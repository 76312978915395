import React from 'react'
import FadeIn from 'react-fade-in'
import { Container, Row, Col, Button } from 'react-bootstrap'
// import Partners from '../components/Partners'
import '../styles/Api.css'

const ApiPage = () => (
  <div className='api-page'>
    <FadeIn delay={500} transitionDuration={750}>
      <div className='heading'>
        <div>АзБуки.ML API - използвайте Natural Language Processing способностите на АзБуки във вашия продукт!</div>
        <div style={{ fontSize: 18 }}>Предлагаме: Граматичен и Морфологичен анализ, Оценка на настроението, Определяне на ключови думи, Резюмиране и Класификация</div>
        <div className='head-actions'>
          <Button variant='dark' href='https://github.com/AzBuki-ML/public-data/blob/master/API.md'>Документация</Button>
          <Button variant='dark' href='/demo'>Демо</Button>
          <Button variant='dark' href='/contacts'>Контакти</Button>
        </div>
      </div>
      <Container>
        <h2 className='usecases-caption'>Примерни приложения:</h2>
        <Row>
          <Col className='option-col zooming-element' sm={12} lg={4}>
            <div className='usecase-option'>
              <p className='usecase-option-description'>
                Онлайн магазин постоянно получава отзиви в коментарите под продуктите, но всички те могат трудно да бъдат обработени на ръка поради липса на достатъчно добро Sentiment Analysis решение.
              </p>
              <i>Използването на АзБуки.ML API за Sentiment Analysis може да помогне!</i>
            </div>
          </Col>
          <Col className='option-col zooming-element' sm={12} lg={4}>
            <div className='usecase-option'>
              <p className='usecase-option-description'>
                Мобилно приложение за научни статии има опцията да резюмира и показва кратки извадки от публикациите на английски. Българската му версия обаче не разполага с автоматизиран софтуер за тази цел и резюметата се пишат на ръка.
              </p>
              <i>Използването на АзБуки.ML API за изваждане на информация и резюмиране ще помогне!</i>
              {/* <br/> */}
            </div>
          </Col>
          <Col className='option-col zooming-element' sm={12} lg={4}>
            <div className='usecase-option'>
              <p className='usecase-option-description'>
                Новинарска медия прави преводи на интервюта от чужд език на български с автоматизирана услуга като Google Translate. В резултата обаче има граматически неточности и липсва пунктуация, което отнема ценно време на редакторите.
              </p>
              <i>Използването на АзБуки.ML API за граматична и пунктуационна корекция може да помогне!</i>
            </div>
          </Col>
        </Row>
      </Container>
      <>
        <br />
        <hr />
        <Container style={{ textAlign: 'center' }}>
          <h3 className='usecases-caption'>Ако сте компания, организация или ентусиаст, заинтересувани от нашето API:</h3><br />
          <Button className='zooming-element' style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: 26 }} variant='warning' href='mailto:rsg.group.here@gmail.com?body=Съобщение&subject=АзБуки.ML'>Свържете се с АзБуки.ML</Button>
        </Container>
        {/* <Partners /> */}
        <br />
        <hr />
        <br />
      </>
    </FadeIn>
  </div>
)

export default ApiPage
