export const posUniversalScheme = {
  ADJ: '#f7a5b3',
  ADP: '#d794ff',
  ADV: 'cyan',
  AUX: '#ffca6a',
  CCONJ: '#d794ff',
  DET: '#86dc86',
  INTJ: '#d794ff',
  NOUN: '#18a1e0',
  NUM: '#93b5e1',
  PART: '#d794ff',
  PRON: '#86dc86',
  PROPN: '#18a1e0',
  PUNCT: '#de7a7a',
  SCONJ: '#d794ff',
  SYM: '#de7a7a',
  VERB: '#ffca6a',
  X: '#dcdcdc'
}

export const dictionaryScheme = {
  '*': ['#dcdcdc', 'друго'],
  noun_male: ['#18a1e0', 'съществително име, мъжки род'],
  verb_transitive_imperfective: ['#ffca6a', 'преходен несвършен глагол'],
  noun_female: ['#18a1e0', 'съществително име, женски род'],
  noun_neutral: ['#18a1e0', 'съществително име, среден род'],
  'noun_plurale-tantum': ['#18a1e0', 'съществително име'],
  adjective: ['#f7a5b3', 'прилагателно име'],
  pronominal_personal: ['#86dc86', 'лично местоимение'],
  pronominal_demonstrative: ['#86dc86', 'показателно местоимение'],
  pronominal_possessive: ['#86dc86', 'притежателно местоимение'],
  pronominal_interrogative: ['#86dc86', 'въпросително местоимение'],
  pronominal_relative: ['#86dc86', 'относително местоимение'],
  pronominal_indefinite: ['#86dc86', 'неопределително местоимение'],
  pronominal_negative: ['#86dc86', 'отрицателно местоимение'],
  pronominal_general: ['#86dc86', 'обобщително местоимение'],
  numeral_cardinal: ['#93b5e1', 'бройно числително име'],
  numeral_ordinal: ['#93b5e1', 'редно числително име'],
  verb: ['#ffca6a', 'глагол'],
  verb_intransitive_imperfective: ['#ffca6a', 'непреходен несвършен глагол'],
  verb_transitive_terminative: ['#ffca6a', 'преходен свършен глагол'],
  verb_intransitive_terminative: ['#ffca6a', 'непреходен свършен глагол'],
  adverb: ['cyan', 'наречие'],
  conjunction: ['#d794ff', 'съюз'],
  interjection: ['#d794ff', 'междуметие'],
  particle: ['#d794ff', 'частица'],
  preposition: ['#d794ff', 'предлог'],
  name_month: ['#51adcf', 'собствено име - месец'],
  'name_bg-various': ['#51adcf', 'собствено име'],
  'name_bg-place': ['#51adcf', 'собствено име - място'],
  name_country: ['#51adcf', 'собствено име - страна'],
  name_capital: ['#51adcf', 'собствено име'],
  name_city: ['#51adcf', 'собствено име - град'],
  name_various: ['#51adcf', 'собствено име'],
  name_popular: ['#51adcf', 'собствено име'],
  name_people_family: ['#51adcf', 'собствено име - семейство'],
  name_people_name: ['#51adcf', 'собствено име'],
  other: ['#dcdcdc', 'друго'],
  prefix: ['#d794ff', 'префикс'],
  suffix: ['#d794ff', 'наставка'],
  abbreviation: ['#d794ff', 'абревиатура'],
  phrase: ['#dccc4d', 'фраза']
}

export const posBulScheme = {
  A: 'Прилагателно, което не се изменя',
  'A-pd': 'Прилагателно, множествено число, членувано',
  'A-pi': 'Прилагателно, множествено число, нечленувано',
  Afsd: 'Прилагателно, женски род, единствено число, членувано',
  Afsi: 'Прилагателно, женски род, единствено число, нечленувано',
  'Ams-a': 'Прилагателно, мъжки род, единствено число, винителен падеж',
  'Ams-d': 'Прилагателно, мъжки род, единствено число, дателен падеж',
  'Ams-e': 'Прилагателно, мъжки род, единствено число, разширена форма',
  Amsf: 'Прилагателно, мъжки род, единствено число, пълен член',
  Amsh: 'Прилагателно, мъжки род, единствено число, кратък член',
  Amsi: 'Прилагателно, мъжки род, единствено число, нечленувано',
  Ansd: 'Прилагателно, среден род, единствено число, членувано',
  Ansi: 'Прилагателно, среден род, единствено число, нечленувано',
  Cc: 'Съчинителен единичен съюз',
  Cp: 'Съчинителен съюз, който е или единичен, или повтарящ се',
  Cr: 'Съчинителен съюз, който се повтаря',
  Cs: 'Подчинителен съюз',
  Dd: 'Наречие, модално',
  Dl: 'Наречие, място',
  Dm: 'Наречие, начин',
  Dq: 'Наречие, количество и степен',
  Dt: 'Наречие, време',
  'H-pd': 'Фамилно име или прилагателно от собствено име, множествено число, членувано',
  'H-pi': 'Фамилно име или прилагателно от собствено име, множествено число, нечленувано',
  Hfsd: 'Фамилно име или прилагателно от собствено име, женски род, единствено число, членувано',
  Hfsi: 'Фамилно име или прилагателно от собствено име, женски род, единствено число, нечленувано',
  Hmsf: 'Фамилно име или прилагателно от собствено име, мъжки род, единствено число, пълен член',
  Hmsh: 'Фамилно име или прилагателно от собствено име, мъжки род, единствено число, кратък член',
  Hmsi: 'Фамилно име или прилагателно от собствено име, мъжки род, единствено число, нечленувано',
  Hnsd: 'Прилагателно от собствено име, среден род, единствено число, членувано',
  Hnsi: 'Прилагателно от собствено име, среден род, единствено число, нечленувано',
  I: 'Междуметие',
  'Mc--d': 'Числително, бройно, членувано',
  'Mc--i': 'Числително, бройно, нечленувано',
  'Mcf-d': 'Числително, бройно, женски род, единствено число, членувано',
  'Mcf-i': 'Числително, бройно, женски род, единствено число, нечленувано',
  Mcfsd: 'Числително, бройно, женски род, единствено число, членувано',
  Mcfsi: 'Числително, бройно, женски род, единствено число, нечленувано',
  'Mcm-d': 'Числително, бройно, мъжки род, единствено число, членувано',
  'Mcm-i': 'Числително, бройно, мъжки род, единствено число, нечленувано',
  Mcmsf: 'Числително, бройно, мъжки род, единствено число, пълен член',
  Mcmsh: 'Числително, бройно, мъжки род, единствено число, кратък член',
  Mcmsi: 'Числително, бройно, мъжки род, единствено число, нечленувано',
  'Mcn-d': 'Числително, бройно, среден род, единствено число, членувано',
  'Mcn-i': 'Числително, бройно, среден род, единствено число, нечленувано',
  Mcnsd: 'Числително, бройно, среден род, единствено число, членувано',
  Mcnsi: 'Числително, бройно, среден род, единствено число, нечленувано',
  'Md--d': 'Числително, наречие, членувано',
  'Md--i': 'Числително, наречие, нечленувано',
  'Mo-pd': 'Числително, редно, множествено число, членувано',
  'Mo-pi': 'Числително, редно, множествено число, нечленувано',
  Mofsd: 'Числително, редно, женски род, единствено число, членувано',
  Mofsi: 'Числително, редно, женски род, единствено число, нечленувано',
  Momsf: 'Числително, редно, мъжки род, единствено число, пълен член',
  Momsh: 'Числително, редно, мъжки род, единствено число, кратък член',
  Momsi: 'Числително, редно, мъжки род, единствено число, нечленувано',
  Monsd: 'Числително, редно, среден род, единствено число, членувано',
  Monsi: 'Числително, редно, среден род, единствено число, нечленувано',
  'My--d': 'Числително, за лица, членувано',
  'My--i': 'Числително, за лица, нечленувано',
  'Nc-ld': 'Съществително, нарицателно, само множествено число, членувано',
  'Nc-li': 'Съществително, нарицателно, само множествено число, нечленувано',
  Ncfpd: 'Съществително, нарицателно, женски род, множествено число, членувано',
  Ncfpi: 'Съществително, нарицателно, женски род, множествено число, нечленувано',
  'Ncfs-v': 'Съществително, нарицателно, женски род, единствено число, звателна форма',
  Ncfsd: 'Съществително, нарицателно, женски род, единствено число, членувано',
  Ncfsi: 'Съществително, нарицателно, женски род, единствено число, нечленувано',
  Ncmpd: 'Съществително, нарицателно, мъжки род, множествено число, членувано',
  Ncmpi: 'Съществително, нарицателно, мъжки род, множествено число, нечленувано',
  'Ncms-a': 'Съществително, нарицателно, мъжки род, единствено число, архаична форма винителен падеж',
  'Ncms-d': 'Съществително, нарицателно, мъжки род, единствено число, архаична форма на дателен падеж',
  'Ncms-v': 'Съществително, нарицателно, мъжки род, единствено число, звателна форма',
  Ncmsd: 'Съществително, нарицателно, мъжки род, единствено число, членувано',
  Ncmsf: 'Съществително, нарицателно, мъжки род, единствено число, пълен член',
  Ncmsh: 'Съществително, нарицателно, мъжки род, единствено число, кратък член',
  Ncmsi: 'Съществително, нарицателно, мъжки род, единствено число, нечленувано',
  Ncmt: 'Съществително, нарицателно, мъжки род, бройна форма',
  Ncnpd: 'Съществително, нарицателно, среден род, множествено число, членувано',
  Ncnpi: 'Съществително, нарицателно, среден род, множествено число, нечленувано',
  Ncnsd: 'Съществително, нарицателно, среден род, единствено число, членувано',
  Ncnsi: 'Съществително, нарицателно, среден род, единствено число, нечленувано',
  'Np-ld': 'Съществително собствено, само множествено число, членувано',
  'Np-li': 'Съществително собствено, само множествено число, нечленувано',
  Npfpd: 'Съществително собствено, женски род, множествено число, членувано',
  Npfpi: 'Съществително собствено, женски род, множествено число, нечленувано',
  'Npfs-v': 'Съществително собствено, женски род, единствено число, звателна форма',
  Npfsi: 'Съществително собствено, женски род, единствено число, нечленувано',
  Npmpd: 'Съществително собствено, мъжки род, множествено число, членувано',
  Npmpi: 'Съществително собствено, мъжки род, множествено число, нечленувано',
  'Npms-a': 'Съществително собствено, мъжки род, единствено число, архаична форма на винителен падеж',
  'Npms-d': 'Съществително собствено, мъжки род, единствено число, членувано',
  'Npms-v': 'Съществително собствено, мъжки род, единствено число, звателна форма',
  Npmsf: 'Съществително , лично име, мъжки род, единствено число, пълен член',
  Npmsh: 'Съществително собствено, мъжки род, единствено число, кратък член',
  Npmsi: 'Съществително собствено, мъжки род, единствено число, нечленувано',
  Npmt: 'Съществително собствено, мъжки род, бройна форма',
  Npnpd: 'Съществително собствено, среден род, множествено число, членувано',
  Npnpi: 'Съществително собствено, среден род, множествено число, нечленувано',
  Npnsd: 'Съществително собствено, среден род, единствено число, членувано',
  Npnsi: 'Съществително собствено, среден род, единствено число, нечленувано',
  'Pca--p': 'Местоимение, обобщително, за качествени признаци, множествено число',
  'Pca--s-f': 'Местоимение, обобщително, за качествени признаци, единствено число, женски род',
  'Pca--s-m': 'Местоимение, обобщително, за качествени признаци, единствено число, мъжки род',
  'Pca--s-n': 'Местоимение, обобщително, за качествени признаци, единствено число, среден род',
  'Pce-as-m': 'Местоимение, обобщително, за лица и предмети, винителен падеж, единствено число, мъжки род',
  'Pce-ds-m': 'Местоимение, обобщително, за лица и предмети, дателен падеж, единствено число, мъжки род',
  'Pce-op': 'Местоимение, обобщително, за лица и предмети, именителен падеж, множествено число',
  'Pce-os-f': 'Местоимение, обобщително, за лица и предмети, именителен падеж, единствено число, женски род',
  'Pce-os-m': 'Местоимение, обобщително, за лица и предмети, именителен падеж, единствено число, мъжки род',
  'Pce-os-n': 'Местоимение, обобщително, за лица и предмети, именителен падеж, единствено число, среден род',
  Pcl: 'Местоимение,  обобщително, за място',
  Pcm: 'Местоимение,  обобщително, за начин',
  'Pcq--p--d': 'Местоимение, обобщително, за количество, множествено число, членувано',
  'Pcq--s-fd': 'Местоимение, обобщително, за количество, единствено число, женски род, членувано',
  'Pcq--s-mf': 'Местоимение, обобщително, за количество, единствено число, мъжки род, пълен член',
  'Pcq--s-mh': 'Местоимение, обобщително, за количество, единствено число, мъжки род, кратък член',
  'Pcq--s-nd': 'Местоимение, обобщително, за количество, единствено число, среден род, членувано',
  Pct: 'Местоимение,  обобщително, за време',
  'Pda--p': 'Местоимение, показателно, за посочване на признаци, множествено число',
  'Pda--s-f': 'Местоимение, показателно, за посочване на признаци, единствено число, женски род',
  'Pda--s-m': 'Местоимение, показателно, за посочване на признаци, единствено число, мъжки род',
  'Pda--s-n': 'Местоимение, показателно, за посочване на признаци, единствено число, среден род',
  'Pde-as-m': 'Местоимение, показателно, винителен падеж, единствено число, мъжки род',
  'Pde-ds-m': 'Местоимение, показателно, дателен падеж, единствено число, мъжки род',
  'Pde-op': 'Местоимение, показателно, именителен падеж, множествено число',
  'Pde-os-f': 'Местоимение, показателно, именителен падеж, единствено число, женски род',
  'Pde-os-m': 'Местоимение, показателно, именителен падеж, единствено число, мъжки род',
  'Pde-os-n': 'Местоимение, показателно, именителен падеж, единствено число, среден род',
  Pdl: 'Местоимение, показателно, за място',
  Pdm: 'Местоимение, показателно, за начин',
  Pdq: 'Местоимение, показателно, за количество',
  Pdt: 'Местоимение, показателно,  за време',
  'Pfa--p': 'Местоимение, неопределително, за качествени признаци, множествено число',
  'Pfa--s-f': 'Местоимение, неопределително, за качествени признаци, единствено число, женски род',
  'Pfa--s-m': 'Местоимение, неопределително, за качествени признаци, единствено число, мъжки род',
  'Pfa--s-n': 'Местоимение, неопределително, за качествени признаци, единствено число, среден род',
  'Pfe--s-n': 'Местоимение, неопределително, единствено число, среден род',
  'Pfe-as-m': 'Местоимение, неопределително, за лица и предмети, винителен падеж, единствено число, мъжки род',
  'Pfe-ds-m': 'Местоимение, неопределително, за лица и предмети, дателен падеж, единствено число, мъжки род',
  'Pfe-op': 'Местоимение, неопределително, за лица и предмети, именителен падеж, множествено число',
  'Pfe-op--d': 'Местоимение, неопределително, за лица и предмети, именителен падеж, множествено число, членувано',
  'Pfe-op--i': 'Местоимение, неопределително, за лица и предмети, именителен падеж, множествено число, нечленувано',
  'Pfe-os-f': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, женски род',
  'Pfe-os-fd': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, женски род, членувано',
  'Pfe-os-fi': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, женски род, нечленувано',
  'Pfe-os-m': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, мъжки род',
  'Pfe-os-mf': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, мъжки род, пълен член',
  'Pfe-os-mh': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, мъжки род, кратък член',
  'Pfe-os-mi': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, мъжки род, нечленувано',
  'Pfe-os-n': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, среден род',
  'Pfe-os-nd': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, среден род, членувано',
  'Pfe-os-ni': 'Местоимение, неопределително, за лица и предмети, именителен падеж, единствено число, среден род, нечленувано',
  Pfl: 'Местоимение, неопределително, за място',
  Pfm: 'Местоимение, неопределително, за начин',
  'Pfp--p': 'Местоимение, неопределително, за притежание, множествено число',
  'Pfp--s-f': 'Местоимение, неопределително, за притежание, единствено число, женски род',
  'Pfp--s-m': 'Местоимение, неопределително, за притежание, единствено число, мъжки род',
  'Pfp--s-n': 'Местоимение, неопределително, за притежание, единствено число, среден род',
  Pfq: 'Местоимение, неопределително, количество',
  'Pfq-----d': 'Местоимение, неопределително, за брой и количество, членувано',
  'Pfq-----i': 'Местоимение, неопределително, количество, нечленувано',
  Pft: 'Местоимение, неопределително, за време',
  'Pfy-----d': 'Местоимение, неопределително, за количество на лица, членувано',
  'Pfy-----i': 'Местоимение, неопределително, за количество на лица, нечленувано',
  'Pia--p': 'Местоимение, въпросително, за признаци, множествено число',
  'Pia--s-f': 'Местоимение, въпросително, за признаци, единствено число, женски род',
  'Pia--s-m': 'Местоимение, въпросително, за признаци, единствено число, мъжки род',
  'Pia--s-n': 'Местоимение, въпросително, за признаци, единствено число, среден род',
  Pic: 'Местоимение, въпросително, защото',
  'Pie-as-m': 'Местоимение, въпросително, за същини, винителен падеж, единствено число, мъжки род',
  'Pie-ds-m': 'Местоимение, въпросително, за същини, дателен падеж, единствено число, мъжки род',
  'Pie-op': 'Местоимение, въпросително, за същини, именителен падеж, множествено число',
  'Pie-os-f': 'Местоимение, въпросително, за същини, именителен падеж, единствено число, женски род',
  'Pie-os-m': 'Местоимение, въпросително, за същини, именителен падеж, единствено число, мъжки род',
  'Pie-os-n': 'Местоимение, въпросително, за същини, именителен падеж, единствено число, среден род',
  Pil: 'Местоимение, въпросително, за място',
  Pim: 'Местоимение, въпросително, за начин',
  'Pip--p': 'Местоимение, въпросително, за притежание, множествено число',
  'Pip--s-f': 'Местоимение, въпросително, за притежание, единствено число, женски род',
  'Pip--s-m': 'Местоимение, въпросително, за притежание, единствено число, мъжки род',
  'Pip--s-n': 'Местоимение, въпросително, за притежание, единствено число, среден род',
  Piq: 'Местоимение, въпросително, за количество',
  Pit: 'Местоимение,  въпросително, за време',
  Piy: 'Местоимение, въпросително, за количество на лица',
  'Pna--p': 'Местоимение, отрицателно, за качествени признаци, множествено число',
  'Pna--s-f': 'Местоимение, отрицателно, за качествени признаци, единствено число, женски род',
  'Pna--s-m': 'Местоимение, отрицателно, за качествени признаци, единствено число, мъжки род',
  'Pna--s-n': 'Местоимение, отрицателно, за качествени признаци, единствено число, среден род',
  'Pne-as-m': 'Местоимение, отрицателно, за лица и предмети, винителен падеж, единствено число, мъжки род',
  'Pne-ds-m': 'Местоимение, отрицателно, за лица и предмети, дателен падеж, единствено число, мъжки род',
  'Pne-op': 'Местоимение, отрицателно, за лица и предмети, именителен падеж, множествено число',
  'Pne-os-f': 'Местоимение, отрицателно, за лица и предмети, именителен падеж, единствено число, женски род',
  'Pne-os-m': 'Местоимение, отрицателно, за лица и предмети, именителен падеж, единствено число, мъжки род',
  'Pne-os-n': 'Местоимение, отрицателно, за лица и предмети, именителен падеж, единствено число, среден род',
  'Pne-os-nd': 'Местоимение, отрицателно, именителен падеж, единствено число, среден род, членувано',
  'Pne-os-ni': 'Местоимение, отрицателно, именителен падеж, единствено число, среден род, членувано',
  Pnl: 'Местоимение, отрицателно, за място',
  Pnm: 'Местоимение, отрицателно, за начин',
  'Pnp--p': 'Местоимение, отрицателно, за притежание, множествено число',
  'Pnp--s-f': 'Местоимение, отрицателно, за притежание, единствено число, женски род',
  'Pnp--s-m': 'Местоимение, отрицателно, за притежание, единствено число, мъжки род',
  'Pnp--s-n': 'Местоимение, отрицателно, за притежание, единствено число, среден род',
  Pnq: 'Местоимение, отрицателно, за количество',
  Pnt: 'Местоимение, отрицателно, за време',
  'Ppe-op1': 'Местоимение, лично, именителен падеж, множествено число, първо лице',
  'Ppe-op2': 'Местоимение, лично, именителен падеж, множествено число, второ лице',
  'Ppe-op3': 'Местоимение, лично, именителен падеж, множествено число, трето лице',
  'Ppe-os1': 'Местоимение, лично, именителен падеж, единствено число, първо лице',
  'Ppe-os2': 'Местоимение, лично, именителен падеж, единствено число, второ лице',
  'Ppe-os3f': 'Местоимение, лично, именителен падеж, единствено число, трето лице, женски род',
  'Ppe-os3m': 'Местоимение, лично, именителен падеж, единствено число, трето лице, мъжки',
  'Ppe-os3n': 'Местоимение, лично, именителен падеж, единствено число, трето лице, среден род',
  Ppelap1: 'Местоимение, лично, пълна форма, винителен падеж, множествено число, първо лице',
  Ppelap2: 'Местоимение, лично, пълна форма, винителен падеж, множествено число, второ лице',
  Ppelap3: 'Местоимение, лично, пълна форма, винителен падеж, множествено число, трето лице',
  Ppelas1: 'Местоимение, лично, пълна форма, винителен падеж, единствено число, първо лице',
  Ppelas2: 'Местоимение, лично, пълна форма, винителен падеж, единствено число, второ лице',
  Ppelas3f: 'Местоимение, лично, пълна форма, винителен падеж, единствено число, трето лице, женски род',
  Ppelas3m: 'Местоимение, лично, пълна форма, винителен падеж, единствено число, трето лице, мъжки',
  Ppelas3n: 'Местоимение, лично, пълна форма, винителен падеж, единствено число, трето лице, среден род',
  Ppeldp1: 'Местоимение, лично, пълна форма, дателен падеж, множествено число, първо лице',
  Ppeldp2: 'Местоимение, лично, пълна форма, дателен падеж, множествено число, второ лице',
  Ppeldp3: 'Местоимение, лично, пълна форма, дателен падеж, множествено число, трето лице',
  Ppelds1: 'Местоимение, лично, пълна форма, дателен падеж, единствено число, първо лице',
  Ppelds2: 'Местоимение, лично, пълна форма, дателен падеж, единствено число, второ лице',
  Ppelds3f: 'Местоимение, лично, пълна форма, дателен падеж, единствено число, трето лице, женски род',
  Ppelds3m: 'Местоимение, лично, пълна форма, дателен падеж, единствено число, трето лице, мъжки',
  Ppelds3n: 'Местоимение, лично, пълна форма, дателен падеж, единствено число, трето лице, среден род',
  Ppetap1: 'Местоимение, лично, кратка форма, винителен падеж, множествено число, първо лице',
  Ppetap2: 'Местоимение, лично, кратка форма, винителен падеж, множествено число, второ лице',
  Ppetap3: 'Местоимение, лично, кратка форма, винителен падеж, множествено число, трето лице',
  Ppetas1: 'Местоимение, лично, кратка форма, винителен падеж, единствено число, първо лице',
  Ppetas2: 'Местоимение, лично, кратка форма, винителен падеж, единствено число, второ лице',
  Ppetas3f: 'Местоимение, лично, кратка форма, винителен падеж, единствено число, трето лице, женски род',
  Ppetas3m: 'Местоимение, лично, кратка форма, винителен падеж, единствено число, трето лице, мъжки',
  Ppetas3n: 'Местоимение, лично, кратка форма, винителен падеж, единствено число, трето лице, среден род',
  Ppetdp1: 'Местоимение, лично, кратка форма, дателен падеж, множествено число, първо лице',
  Ppetdp2: 'Местоимение, лично, кратка форма, дателен падеж, множествено число, второ лице',
  Ppetdp3: 'Местоимение, лично, кратка форма, дателен падеж, множествено число, трето лице',
  Ppetds1: 'Местоимение, лично, кратка форма, дателен падеж, единствено число, първо лице',
  Ppetds2: 'Местоимение, лично, кратка форма, дателен падеж, единствено число, второ лице',
  Ppetds3f: 'Местоимение, лично, кратка форма, дателен падеж, единствено число, трето лице, женски род',
  Ppetds3m: 'Местоимение, лично, кратка форма, дателен падеж, единствено число, трето лице, мъжки род',
  Ppetds3n: 'Местоимение, лично, кратка форма, дателен падеж, единствено число, трето лице, среден род',
  Ppetsp1: 'Местоимение, лично, кратка форма, дателен-притежателен, множествено число, първо лице',
  Ppetsp2: 'Местоимение, лично, кратка форма, дателен-притежателен, множествено число, второ лице',
  Ppetsp3: 'Местоимение, лично, кратка форма, дателен-притежателен, множествено число, трето лице',
  Ppetss1: 'Местоимение, лично, кратка форма, дателен-притежателен, единствено число, първо лице',
  Ppetss2: 'Местоимение, лично, кратка форма, дателен-притежателен, единствено число, второ лице',
  Ppetss3f: 'Местоимение, лично, кратка форма, дателен-притежателен, единствено число, трето лице, женски род',
  Ppetss3m: 'Местоимение, лично, кратка форма, дателен-притежателен, единствено число, трето лице, мъжки род',
  Ppetss3n: 'Местоимение, лично, кратка форма, дателен-притежателен, единствено число, трето лице, среден род',
  Ppxla: 'Местоимение, лично възвратно, пълна форма, винителен падеж',
  Ppxta: 'Местоимение, лично възвратно, кратка форма, винителен падеж',
  Ppxtd: 'Местоимение, лично възвратно, кратка форма, дателен падеж',
  Ppxts: 'Местоимение, лично възвратно, кратка форма, дателен-притежателен',
  'Pra--p': 'Местоимение, относително, за качествени признаци, множествено число',
  'Pra--s-f': 'Местоимение, относително, за качествени признаци, единствено число, женски род',
  'Pra--s-m': 'Местоимение, относително, за качествени признаци, единствено число, мъжки род',
  'Pra--s-n': 'Местоимение, относително, за качествени признаци, единствено число, среден род',
  'Pre--s': 'Местоимение, относително, за лица и предмети, единствено число',
  'Pre-as-m': 'Местоимение, относително, за лица и предмети, винителен падеж, единствено число, мъжки род',
  'Pre-ds-m': 'Местоимение, относително, за лица и предмети, дателен падеж, единствено число, мъжки род',
  'Pre-op': 'Местоимение, относително, за лица и предмети, именителен падеж, множествено число',
  'Pre-os-f': 'Местоимение, относително, за лица и предмети, именителен падеж, единствено число, женски род',
  'Pre-os-m': 'Местоимение, относително, за лица и предмети, именителен падеж, единствено число, мъжки род',
  'Pre-os-n': 'Местоимение, относително, за лица и предмети, именителен падеж, единствено число, среден род',
  Prl: 'Местоимение, относително,  за място',
  Prm: 'Местоимение,  относително, за начин',
  'Prp--p': 'Местоимение, относително, за притежание, множествено число',
  'Prp--s-f': 'Местоимение, относително, за притежание, единствено число, женски род',
  'Prp--s-m': 'Местоимение, относително, за притежание, единствено число, мъжки род',
  'Prp--s-n': 'Местоимение, относително, за притежание, единствено число, среден род',
  Prq: 'Местоимение,  относително, за количество',
  Prt: 'Местоимение,  относително, за време',
  'Psol-p1-d': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, първо лице, членувано',
  'Psol-p1-i': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, първо лице, нечленувано',
  'Psol-p2-d': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, второ лице, членувано',
  'Psol-p2-i': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, второ лице, нечленувано',
  'Psol-p3-df': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, трето лице, членувано, женски род на притежателя',
  'Psol-p3-dm': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, трето лице, членувано, мъжки род на притежателя',
  'Psol-p3-dn': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, трето лице, членувано, среден род на притежателя',
  'Psol-p3-if': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, трето лице, нечленувано, женски род на притежател',
  'Psol-p3-im': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, трето лице, нечленувано, мъжки род на притежателя',
  'Psol-p3-in': 'Местоимение, притежателно, един притежател, пълна форма, множествено число, трето лице, нечленувано, среден род на притежателя',
  'Psol-s1fd': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, женски род, членувано',
  'Psol-s1fi': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, женски род, нечленувано',
  'Psol-s1mf': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, мъжки род, членувано (пълен член)',
  'Psol-s1mh': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, мъжки род, членувано (кратък член)',
  'Psol-s1mi': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, мъжки род, нечленувано',
  'Psol-s1nd': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, среден род, членувано',
  'Psol-s1ni': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, първо лице, среден род, нечленувано',
  'Psol-s2fd': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, женски род, членувано',
  'Psol-s2fi': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, женски род, нечленувано',
  'Psol-s2mf': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, мъжки род, членувано (пълен член)',
  'Psol-s2mh': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, мъжки род, членувано (кратък член)',
  'Psol-s2mi': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, мъжки род, нечленувано',
  'Psol-s2nd': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, среден род, членувано',
  'Psol-s2ni': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, второ лице, среден род, нечленувано',
  'Psol-s3fdf': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, женски род, членувано, женски род на притежателя',
  'Psol-s3fdm': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, женски род, членувано, мъжки род на притежателя',
  'Psol-s3fdn': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, женски род, членувано, среден род на притежателя',
  'Psol-s3fif': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, женски род, нечленувано, женски род на притежателя',
  'Psol-s3fim': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, женски род, нечленувано, мъжки род на притежателя',
  'Psol-s3fin': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, женски род, нечленувано, среден род на притежателя',
  'Psol-s3mff': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, членувано (пълен член), женски род на притежателя',
  'Psol-s3mfm': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, членувано (пълен член), мъжки род на притежателя',
  'Psol-s3mfn': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, членувано (пълен член), среден род на притежателя',
  'Psol-s3mhf': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, членувано (кратък член), женски род на притежателя',
  'Psol-s3mhm': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, членувано (кратък член), мъжки род на притежателя',
  'Psol-s3mhn': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, членувано (кратък член), среден род на притежателя',
  'Psol-s3mif': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, нечленувано, женски род на притежателя',
  'Psol-s3mim': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, нечленувано, мъжки род на притежателя',
  'Psol-s3min': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, мъжки род, нечленувано, среден род на притежателя',
  'Psol-s3ndf': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, среден род, членувано, женски род на притежателя',
  'Psol-s3ndm': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, среден род, членувано, мъжки род на притежателя',
  'Psol-s3ndn': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, среден род, членувано, среден род на притежателя',
  'Psol-s3nif': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, среден род, нечленувано, женски род на притежателя',
  'Psol-s3nim': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, среден род, нечленувано, мъжки род на притежателя',
  'Psol-s3nin': 'Местоимение, притежателно, един притежател, пълна форма, единствено число, трето лице, среден род, нечленувано, среден род на притежателя',
  'Psot--1': 'Местоимение, притежателно, един притежател, кратка форма, първо лице',
  'Psot--2': 'Местоимение, притежателно, един притежател, кратка форма, второ лице',
  'Psot--3--f': 'Местоимение, притежателно, един притежател, кратка форма, трето лице, женски род на притежателя',
  'Psot--3--m': 'Местоимение, притежателно, един притежател, кратка форма, трето лице, притежател мъжки',
  'Psot--3--n': 'Местоимение, притежателно, един притежател, кратка форма, трето лице, среден род на притежателя',
  Psxla: 'Местоимение, притежателно, възвратно, пълна форма, винителен падеж',
  Psxld: 'Местоимение, притежателно, възвратно, пълна форма, дателен падеж',
  'Psxlop--d': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, множествено число, членувано',
  'Psxlop--i': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, множествено число, нечленувано',
  'Psxlos-fd': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, женски род, членувано',
  'Psxlos-fi': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, женски род, нечленувано',
  'Psxlos-mf': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, мъжки род, членувано (пълен член)',
  'Psxlos-mh': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, мъжки род, членувано (кратък член)',
  'Psxlos-mi': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, мъжки род, нечленувано',
  'Psxlos-nd': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, среден род, членувано',
  'Psxlos-ni': 'Местоимение, притежателно, възвратно, пълна форма, именителен падеж, единствено число, среден род, нечленувано',
  Psxto: 'Местоимение, притежателно, възвратно, кратка форма, именителен падеж',
  'Pszl-p1-d': 'Местоимение, притежателно, много притежатели, пълна форма, множествено число, първо лице, членувано',
  'Pszl-p1-i': 'Местоимение, притежателно, много притежатели, пълна форма, множествено число, първо лице, нечленувано',
  'Pszl-p2-d': 'Местоимение, притежателно, много притежатели, пълна форма, множествено число, второ лице, членувано',
  'Pszl-p2-i': 'Местоимение, притежателно, много притежатели, пълна форма, множествено число, второ лице, нечленувано',
  'Pszl-p3-d': 'Местоимение, притежателно, много притежатели, пълна форма, множествено число, трето лице, членувано',
  'Pszl-p3-i': 'Местоимение, притежателно, много притежатели, пълна форма, множествено число, трето лице, нечленувано',
  'Pszl-s1fd': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, женски род, членувано',
  'Pszl-s1fi': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, женски род, нечленувано',
  'Pszl-s1mf': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, мъжки род, членувано (пълен член)',
  'Pszl-s1mh': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, мъжки род, членувано (кратък член)',
  'Pszl-s1mi': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, мъжки род, нечленувано',
  'Pszl-s1nd': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, среден род, членувано',
  'Pszl-s1ni': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, първо лице, среден род, нечленувано',
  'Pszl-s2fd': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, женски род, членувано',
  'Pszl-s2fi': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, женски род, нечленувано',
  'Pszl-s2mf': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, мъжки род, членувано (пълен член)',
  'Pszl-s2mh': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, мъжки род, членувано (кратък член)',
  'Pszl-s2mi': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, мъжки род, нечленувано',
  'Pszl-s2nd': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, среден род, членувано',
  'Pszl-s2ni': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, второ лице, среден род, нечленувано',
  'Pszl-s3fd': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, женски род, членувано',
  'Pszl-s3fi': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, женски род, нечленувано',
  'Pszl-s3mf': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, мъжки род, членувано (пълен член)',
  'Pszl-s3mh': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, мъжки род, членувано (кратък член)',
  'Pszl-s3mi': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, мъжки род, нечленувано',
  'Pszl-s3nd': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, среден род, членувано',
  'Pszl-s3ni': 'Местоимение, притежателно, много притежатели, пълна форма, единствено число, трето лице, среден род, нечленувано',
  'Pszt--1': 'Местоимение, притежателно, много притежатели, кратка форма, първо лице',
  'Pszt--2': 'Местоимение, притежателно, много притежатели, кратка форма, второ лице',
  'Pszt--3': 'Местоимение, притежателно, много притежатели, кратка форма, трето лице',
  R: 'Предлог',
  Ta: 'Частица, утвърдителна',
  Te: 'Частица, усилваща',
  Tg: 'Частица, за сравнителна и превъзходна степен',
  Ti: 'Частица, въпросителна',
  Tm: 'Частица, модална',
  Tn: 'Частица, отрицателна',
  Tv: 'Частица, глаголна',
  Tx: 'Частица, спомагателна',
  'Viitcar-p-d': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, множествено число, членуван',
  'Viitcar-p-i': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, множествено число, нечленуван',
  'Viitcar-sfd': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, женски род, членуван',
  'Viitcar-sfi': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, женски род, нечленуван',
  'Viitcar-smf': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, членуван (пълен член)',
  'Viitcar-smh': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, членуван (кратък член)',
  'Viitcar-smi': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, нечленуван',
  'Viitcar-snd': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, среден род, членуван',
  'Viitcar-sni': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, среден род, нечленуван',
  'Viitcat-p-d': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, множествено число, членуван',
  'Viitcat-p-i': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, множествено число, нечленуван',
  'Viitcat-sfd': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, женски род, членуван',
  'Viitcat-sfi': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, женски род, нечленуван',
  'Viitcat-smf': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, мъжки род, членуван (пълен член)',
  'Viitcat-smh': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, мъжки род, членуван (кратък член)',
  'Viitcat-smi': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, мъжки род, нечленуван',
  'Viitcat-snd': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, среден род, членуван',
  'Viitcat-sni': 'Глагол, спомагателен (бивам), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, среден род, нечленуван',
  'Viitf-m1p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, първо лице, множествено число',
  'Viitf-m1s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, първо лице, единствено число',
  'Viitf-m2p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, второ лице, множествено число',
  'Viitf-m2s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, второ лице, единствено число',
  'Viitf-m3p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, трето лице, множествено число',
  'Viitf-m3s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, трето лице, единствено число',
  'Viitf-o1p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, минало свършено време, първо лице, множествено число',
  'Viitf-o1s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, минало свършено време, първо лице, единствено число',
  'Viitf-o2p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, минало свършено време, второ лице, множествено число',
  'Viitf-o2s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, минало свършено време, второ лице, единствено число',
  'Viitf-o3p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, минало свършено време, трето лице, множествено число',
  'Viitf-o3s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Viitf-r1p': 'Глагол, спомагателен (бивам), несвършен, преходен, показателни, сегашно време, първо лице, множествено',
  'Viitf-r1s': 'Глагол, спомагателен (бивам), несвършен, преходен, показателни, сегашно време, първо лице, единствено',
  'Viitf-r2p': 'Глагол, спомагателен (бивам), несвършен, преходен, показателни, сегашно време, второ лице, множествено',
  'Viitf-r2s': 'Глагол, спомагателен (бивам), несвършен, преходен, показателни, сегашно време, второ лице, единствено',
  'Viitf-r3p': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Viitf-r3s': 'Глагол, спомагателен (бивам), несвършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  Viitg: 'Глагол, спомагателен (бивам), несвършен, преходен, деепричастие',
  'Viitz--2p': 'Глагол, спомагателен (бивам), несвършен, преходен, повелително наклонение, второ лице, множествено число',
  'Viitz--2s': 'Глагол, спомагателен (бивам), несвършен, преходен, повелително наклонение, второ лице, единствено число',
  'Vniicam-sni': 'Глагол, безличен, несвършен, непреходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vniicao-sni': 'Глагол, безличен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vniif-m3s': 'Глагол, безличен, несвършен, непреходен, изявително наклонение, трето лице, единствено число',
  'Vniif-o3s': 'Глагол, безличен, несвършен, непреходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vniif-r3s': 'Глагол, безличен, несвършен, непреходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vnitcam-sni': 'Глагол, безличен, несвършен, преходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vnitcao-sni': 'Глагол, безличен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vnitf-m3s': 'Глагол, безличен, несвършен, преходен, изявително наклонение, трето лице, единствено число',
  'Vnitf-o3s': 'Глагол, безличен, несвършен, преходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vnitf-r3s': 'Глагол, безличен, несвършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vnpicam-sni': 'Глагол, безличен, свършен, непреходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vnpicao-sni': 'Глагол, безличен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vnpif-m3s': 'Глагол, безличен, свършен, непреходен, изявително наклонение, трето лице, единствено число',
  'Vnpif-o3s': 'Глагол, безличен, свършен, непреходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vnpif-r3s': 'Глагол, безличен, свършен, непреходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vnptcam-sni': 'Глагол, безличен, свършен, преходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vnptcao-sni': 'Глагол, безличен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vnptf-m3s': 'Глагол, безличен, свършен, преходен, изявително наклонение, трето лице, единствено число',
  'Vnptf-o3s': 'Глагол, безличен, свършен, преходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vnptf-r3s': 'Глагол, безличен, свършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vpiicam-p-i': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, множествено число, нечленуван',
  'Vpiicam-sfi': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, единствено число, женски род, нечленуван',
  'Vpiicam-smi': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, единствено число, мъжки род, нечленуван',
  'Vpiicam-sni': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vpiicao-p-d': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, множествено число, членуван',
  'Vpiicao-p-i': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, множествено число, нечленуван',
  'Vpiicao-sfd': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, членуван',
  'Vpiicao-sfi': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, нечленуван',
  'Vpiicao-smf': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (пълен член)',
  'Vpiicao-smh': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (кратък член)',
  'Vpiicao-smi': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, нечленуван',
  'Vpiicao-snd': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, членуван',
  'Vpiicao-sni': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vpiicar-p-d': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, множествено число, членуван',
  'Vpiicar-p-i': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, множествено число, нечленуван',
  'Vpiicar-sfd': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, женски род, членуван',
  'Vpiicar-sfi': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, женски род, нечленуван',
  'Vpiicar-smf': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, членуван (пълен член)',
  'Vpiicar-smh': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, членуван (кратък член)',
  'Vpiicar-smi': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, нечленуван',
  'Vpiicar-snd': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, среден род, членуван',
  'Vpiicar-sni': 'Глагол, личен, несвършен, непреходен, причастие, деятелен залог, сегашно време, единствено число, среден род, нечленуван',
  'Vpiif-m1p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, първо лице, множествено число',
  'Vpiif-m1s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, първо лице, единствено число',
  'Vpiif-m2p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, второ лице, множествено число',
  'Vpiif-m2s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, второ лице, единствено число',
  'Vpiif-m3p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, трето лице, множествено число',
  'Vpiif-m3s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, трето лице, единствено число',
  'Vpiif-o1p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, минало свършено време, първо лице, множествено число',
  'Vpiif-o1s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, минало свършено време, първо лице, единствено число',
  'Vpiif-o2p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, минало свършено време, второ лице, множествено число',
  'Vpiif-o2s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, минало свършено време, второ лице, единствено число',
  'Vpiif-o3p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, минало свършено време, трето лице, множествено число',
  'Vpiif-o3s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vpiif-r1p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, сегашно време, първо лице, множествено число',
  'Vpiif-r1s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, сегашно време, първо лице, единствено число',
  'Vpiif-r2p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, сегашно време, второ лице, множествено число',
  'Vpiif-r2s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, сегашно време, второ лице, единствено число',
  'Vpiif-r3p': 'Глагол, личен, несвършен, непреходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Vpiif-r3s': 'Глагол, личен, несвършен, непреходен, изявително наклонение, сегашно време, трето лице, единствено число',
  Vpiig: 'Глагол, личен, несвършен, непреходен, деепричастие',
  'Vpiiz--2p': 'Глагол, личен, несвършен, непреходен, повелително наклонение, второ лице, множествено число',
  'Vpiiz--2s': 'Глагол, личен, несвършен, непреходен, повелително наклонение, второ лице, единствено число',
  'Vpitcam-p-i': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, множествено число, нечленуван',
  'Vpitcam-sfi': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, единствено число, женски род, нечленуван',
  'Vpitcam-smi': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, единствено число, мъжки род, нечленуван',
  'Vpitcam-sni': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vpitcao-p-d': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, множествено число, членуван',
  'Vpitcao-p-i': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, множествено число, нечленуван',
  'Vpitcao-sfd': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, членуван',
  'Vpitcao-sfi': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, нечленуван',
  'Vpitcao-smf': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (пълен член)',
  'Vpitcao-smh': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (кратък член)',
  'Vpitcao-smi': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, нечленуван',
  'Vpitcao-snd': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, членуван',
  'Vpitcao-sni': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vpitcar-p-d': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, множествено число, членуван',
  'Vpitcar-p-i': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, множествено число, нечленуван',
  'Vpitcar-sfd': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, женски род, членуван',
  'Vpitcar-sfi': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, женски род, нечленуван',
  'Vpitcar-smf': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, членуван (пълен член)',
  'Vpitcar-smh': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, членуван (кратък член)',
  'Vpitcar-smi': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, мъжки род, нечленуван',
  'Vpitcar-snd': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, среден род, членуван',
  'Vpitcar-sni': 'Глагол, личен, несвършен, преходен, причастие, деятелен залог, сегашно време, единствено число, среден род, нечленуван',
  'Vpitcv--p-d': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, множествено число, членуван',
  'Vpitcv--p-i': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, множествено число, нечленуван',
  'Vpitcv--sfd': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, женски род, членуван',
  'Vpitcv--sfi': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, женски род, нечленуван',
  'Vpitcv--smf': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, мъжки род, членуван (пълен член)',
  'Vpitcv--smh': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, мъжки род, членуван (кратък член)',
  'Vpitcv--smi': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, мъжки род, нечленуван',
  'Vpitcv--snd': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, среден род, членуван',
  'Vpitcv--sni': 'Глагол, личен, несвършен, преходен, причастие, страдателен залог, единствено число, среден род, нечленуван',
  'Vpitf-m1p': 'Глагол, личен, несвършен, преходен, изявително наклонение, първо лице, множествено число',
  'Vpitf-m1s': 'Глагол, личен, несвършен, преходен, изявително наклонение, първо лице, единствено число',
  'Vpitf-m2p': 'Глагол, личен, несвършен, преходен, изявително наклонение, второ лице, множествено число',
  'Vpitf-m2s': 'Глагол, личен, несвършен, преходен, изявително наклонение, второ лице, единствено число',
  'Vpitf-m3p': 'Глагол, личен, несвършен, преходен, изявително наклонение, трето лице, множествено число',
  'Vpitf-m3s': 'Глагол, личен, несвършен, преходен, изявително наклонение, трето лице, единствено число',
  'Vpitf-o1p': 'Глагол, личен, несвършен, преходен, изявително наклонение, минало свършено време, първо лице, множествено число',
  'Vpitf-o1s': 'Глагол, личен, несвършен, преходен, изявително наклонение, минало свършено време, първо лице, единствено число',
  'Vpitf-o2p': 'Глагол, личен, несвършен, преходен, изявително наклонение, минало свършено време, второ лице, множествено число',
  'Vpitf-o2s': 'Глагол, личен, несвършен, преходен, изявително наклонение, минало свършено време, второ лице, единствено число',
  'Vpitf-o3p': 'Глагол, личен, несвършен, преходен, изявително наклонение, минало свършено време, трето лице, множествено число',
  'Vpitf-o3s': 'Глагол, личен, несвършен, преходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vpitf-r1p': 'Глагол, личен, несвършен, преходен, изявително наклонение, сегашно време, първо лице, множествено число',
  'Vpitf-r1s': 'Глагол, личен, несвършен, преходен, изявително наклонение, сегашно време, първо лице, единствено число',
  'Vpitf-r2p': 'Глагол, личен, несвършен, преходен, изявително наклонение, сегашно време, второ лице, множествено число',
  'Vpitf-r2s': 'Глагол, личен, несвършен, преходен, изявително наклонение, сегашно време, второ лице, единствено число',
  'Vpitf-r3p': 'Глагол, личен, несвършен, преходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Vpitf-r3s': 'Глагол, личен, несвършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  Vpitg: 'Глагол, личен, несвършен, преходен, деепричастие',
  'Vpitz--2p': 'Глагол, личен, несвършен, преходен, повелително наклонение, второ лице, множествено число',
  'Vpitz--2s': 'Глагол, личен, несвършен, преходен, повелително наклонение, второ лице, единствено число',
  'Vppicam-p-i': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, множествено число, нечленуван',
  'Vppicam-sfi': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, единствено число, женски род, нечленуван',
  'Vppicam-smi': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, единствено число, мъжки род, нечленуван',
  'Vppicam-sni': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vppicao-p-d': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, множествено число, членуван',
  'Vppicao-p-i': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, множествено число, нечленуван',
  'Vppicao-sfd': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, членуван',
  'Vppicao-sfi': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, нечленуван',
  'Vppicao-smf': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (пълен член)',
  'Vppicao-smh': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (кратък член)',
  'Vppicao-smi': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, нечленуван',
  'Vppicao-snd': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, членуван',
  'Vppicao-sni': 'Глагол, личен, свършен, непреходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vppif-m1p': 'Глагол, личен, свършен, непреходен, изявително наклонение, първо лице, множествено число',
  'Vppif-m2p': 'Глагол, личен, свършен, непреходен, изявително наклонение, второ лице, множествено число',
  'Vppif-m2s': 'Глагол, личен, свършен, непреходен, изявително наклонение, второ лице, единствено число',
  'Vppif-m3p': 'Глагол, личен, свършен, непреходен, изявително наклонение, трето лице, множествено число',
  'Vppif-m3s': 'Глагол, личен, свършен, непреходен, изявително наклонение, трето лице, единствено число',
  'Vppif-o1p': 'Глагол, личен, свършен, непреходен, изявително наклонение, минало свършено време, първо лице, множествено число',
  'Vppif-o1s': 'Глагол, личен, свършен, непреходен, изявително наклонение, минало свършено време, първо лице, единствено число',
  'Vppif-o2p': 'Глагол, личен, свършен, непреходен, изявително наклонение, минало свършено време, второ лице, множествено число',
  'Vppif-o2s': 'Глагол, личен, свършен, непреходен, изявително наклонение, минало свършено време, второ лице, единствено число',
  'Vppif-o3p': 'Глагол, личен, свършен, непреходен, изявително наклонение, минало свършено време, трето лице, множествено число',
  'Vppif-o3s': 'Глагол, личен, свършен, непреходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vppif-r1p': 'Глагол, личен, свършен, непреходен, изявително наклонение, сегашно време, първо лице, множествено число',
  'Vppif-r1s': 'Глагол, личен, свършен, непреходен, изявително наклонение, сегашно време, първо лице, единствено число',
  'Vppif-r2p': 'Глагол, личен, свършен, непреходен, изявително наклонение, сегашно време, второ лице, множествено число',
  'Vppif-r2s': 'Глагол, личен, свършен, непреходен, изявително наклонение, сегашно време, второ лице, единствено число',
  'Vppif-r3p': 'Глагол, личен, свършен, непреходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Vppif-r3s': 'Глагол, личен, свършен, непреходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vppiz--2p': 'Глагол, личен, свършен, непреходен, повелително наклонение, второ лице, множествено число',
  'Vppiz--2s': 'Глагол, личен, свършен, непреходен, повелително наклонение, второ лице, единствено число',
  'Vpptcam-p-i': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, множествено число, нечленуван',
  'Vpptcam-sfi': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, единствено число, женски род, нечленуван',
  'Vpptcam-smi': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, единствено число, мъжки род, нечленуван',
  'Vpptcam-sni': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vpptcao-p-d': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, множествено число, членуван',
  'Vpptcao-p-i': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, множествено число, нечленуван',
  'Vpptcao-sfd': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, членуван',
  'Vpptcao-sfi': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, женски род, нечленуван',
  'Vpptcao-smf': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (пълен член)',
  'Vpptcao-smh': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, членуван (кратък член)',
  'Vpptcao-smi': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, мъжки род, нечленуван',
  'Vpptcao-snd': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, членуван',
  'Vpptcao-sni': 'Глагол, личен, свършен, преходен, причастие, деятелен залог, минало свършено време, единствено число, среден род, нечленуван',
  'Vpptcv--p-d': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, множествено число, членуван',
  'Vpptcv--p-i': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, множествено число, нечленуван',
  'Vpptcv--sfd': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, женски род, членуван',
  'Vpptcv--sfi': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, женски род, нечленуван',
  'Vpptcv--smf': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, мъжки род, членуван (пълен член)',
  'Vpptcv--smh': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, мъжки род, членуван (кратък член)',
  'Vpptcv--smi': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, мъжки род, нечленуван',
  'Vpptcv--snd': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, среден род, членуван',
  'Vpptcv--sni': 'Глагол, личен, свършен, преходен, причастие, страдателен залог, единствено число, среден род, нечленуван',
  'Vpptf-m1p': 'Глагол, личен, свършен, преходен, изявително наклонение, минало несвършено време, първо лице, множествено число',
  'Vpptf-m1s': 'Глагол, личен, свършен, преходен, изявително наклонение, минало несвършено време, първо лице, единствено число',
  'Vpptf-m2p': 'Глагол, личен, свършен, преходен, изявително наклонение, минало несвършено време, второ лице, множествено число',
  'Vpptf-m2s': 'Глагол, личен, свършен, преходен, изявително наклонение, минало несвършено време, второ лице, единствено число',
  'Vpptf-m3p': 'Глагол, личен, свършен, преходен, изявително наклонение, минало несвършено време, трето лице, множествено число',
  'Vpptf-m3s': 'Глагол, личен, свършен, преходен, изявително наклонение, минало несвършено време, трето лице, единствено число',
  'Vpptf-o1p': 'Глагол, личен, свършен, преходен, изявително наклонение, минало свършено време, първо лице, множествено число',
  'Vpptf-o1s': 'Глагол, личен, свършен, преходен, изявително наклонение, минало свършено време, първо лице, единствено число',
  'Vpptf-o2p': 'Глагол, личен, свършен, преходен, изявително наклонение, минало свършено време, второ лице, множествено число',
  'Vpptf-o2s': 'Глагол, личен, свършен, преходен, изявително наклонение, минало свършено време, второ лице, единствено число',
  'Vpptf-o3p': 'Глагол, личен, свършен, преходен, изявително наклонение, минало свършено време, трето лице, множествено число',
  'Vpptf-o3s': 'Глагол, личен, свършен, преходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vpptf-r1p': 'Глагол, личен, свършен, преходен, изявително наклонение, сегашно време, първо лице, множествено число',
  'Vpptf-r1s': 'Глагол, личен, свършен, преходен, изявително наклонение, сегашно време, първо лице, единствено число',
  'Vpptf-r2p': 'Глагол, личен, свършен, преходен, изявително наклонение, сегашно време, второ лице, множествено число',
  'Vpptf-r2s': 'Глагол, личен, свършен, преходен, изявително наклонение, сегашно време, второ лице, единствено число',
  'Vpptf-r3p': 'Глагол, личен, свършен, преходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Vpptf-r3s': 'Глагол, личен, свършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vpptz--2p': 'Глагол, личен, свършен, преходен, повелително наклонение, второ лице, множествено число',
  'Vpptz--2s': 'Глагол, личен, свършен, преходен, повелително наклонение, второ лице, единствено число',
  'Vxitcat-p-d': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, множествено число, членуван',
  'Vxitcat-p-i': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, множествено число, нечленуван',
  'Vxitcat-sfd': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, женски род, членуван',
  'Vxitcat-sfi': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, женски род, нечленуван',
  'Vxitcat-smf': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, мъжки род, членуван (пълен член)',
  'Vxitcat-smh': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, мъжки род, членуван (кратък член)',
  'Vxitcat-smi': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, мъжки род, нечленуван',
  'Vxitcat-snd': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, среден род, членуван',
  'Vxitcat-sni': 'Глагол, спомагателен (да съм), несвършен, преходен, причастие, деятелен залог, минало време, единствено число, среден род, нечленуван',
  'Vxitf-r1p': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, сегашно време, първо лице, множествено',
  'Vxitf-r1s': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, сегашно време, първо лице, единствено',
  'Vxitf-r2p': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, сегашно време, второ лице, множествено',
  'Vxitf-r2s': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, сегашно време, второ лице, единствено',
  'Vxitf-r3p': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Vxitf-r3s': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  'Vxitf-t1p': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, минало време, първо лице, множествено число',
  'Vxitf-t1s': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, минало време, първо лице, единствено число',
  'Vxitf-t2p': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, минало време, второ лице, множествено число',
  'Vxitf-t2s': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, минало време, второ лице, единствено число',
  'Vxitf-t3p': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, минало време, трето лице, множествено число',
  'Vxitf-t3s': 'Глагол, спомагателен (да съм), несвършен, преходен, изявително наклонение, минало време, трето лице, единствено число',
  Vxitg: 'Глагол, спомагателен (да съм), несвършен, преходен, деепричастие',
  'Vxitu-o1p': 'Глагол, спомагателен (да съм), несвършен, преходен, условно наклонение, минало свършено време, първо лице, множествено число',
  'Vxitu-o1s': 'Глагол, спомагателен (да съм), несвършен, преходен, условно наклонение, минало свършено време, първо лице, единствено число',
  'Vxitu-o2p': 'Глагол, спомагателен (да съм), несвършен, преходен, условно наклонение, минало свършено време, второ лице, множествено число',
  'Vxitu-o2s': 'Глагол, спомагателен (да съм), несвършен, преходен, условно наклонение, минало свършено време, второ лице, единствено число',
  'Vxitu-o3p': 'Глагол, спомагателен (да съм), несвършен, преходен, условно, минало свършено време, трето лице, множествено число',
  'Vxitu-o3s': 'Глагол, спомагателен (да съм), несвършен, преходен, условно, минало свършено време, трето лице, единствено число',
  'Vyptcam-p-i': 'Глагол, спомагателен (да бъда), свършен, преходен, причастие, деятелен залог, множествено число, нечленуван',
  'Vyptcam-sfi': 'Глагол, спомагателен (да бъда), свършен, преходен, причастие, деятелен залог, единствено число, женски род, нечленуван',
  'Vyptcam-smi': 'Глагол, спомагателен (да бъда), свършен, преходен, причастие, деятелен залог, единствено число, мъжки род, нечленуван',
  'Vyptcam-sni': 'Глагол, спомагателен (да бъда), свършен, преходен, причастие, деятелен залог, единствено число, среден род, нечленуван',
  'Vyptf-m1p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, първо лице, множествено число',
  'Vyptf-m1s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, първо лице, единствено число',
  'Vyptf-m2p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, второ лице, множествено число',
  'Vyptf-m2s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, второ лице, единствено число',
  'Vyptf-m3p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, трето лице, множествено число',
  'Vyptf-m3s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, трето лице, единствено число',
  'Vyptf-o1p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, минало свършено време, първо лице, множествено число',
  'Vyptf-o1s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, минало свършено време, първо лице, единствено число',
  'Vyptf-o2p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, минало свършено време, второ лице, множествено число',
  'Vyptf-o2s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, минало свършено време, второ лице, единствено число',
  'Vyptf-o3p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, минало свършено време, трето лице, множествено число',
  'Vyptf-o3s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, минало свършено време, трето лице, единствено число',
  'Vyptf-r1p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, сегашно време, първо лице, множествено число',
  'Vyptf-r1s': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, сегашно време, първо лице, единствено число',
  'Vyptf-r2p': 'Глагол, спомагателен (да бъда), свършен, преходен, изявително наклонение, сегашно време, второ лице, множествено число',
  'Vyptf-r2s': 'Глагол, спомагателни (бъда (да)), свършен, преходен, показателни, сегашно време, второ лице, единствено число',
  'Vyptf-r3p': 'Глагол, спомагателно (бъда (да)), свършен, преходен, изявително наклонение, сегашно време, трето лице, множествено число',
  'Vyptf-r3s': 'Глагол, спомагателно (бъда (да)), свършен, преходен, изявително наклонение, сегашно време, трето лице, единствено число',
  Vyptg: 'Глагол, спомагателни (бъда (да)), свършен, преходен, деепричастие',
  'Vyptz--2p': 'Глагол, спомагателно (бъда (да)), свършен, преходен, наложително, второ лице, множествено число',
  'Vyptz--2s': 'Глагол, спомагателно (бъда (да)), свършен, преходен, наложително, второ лице, единствено число'
}

export const emotionScheme = {
  neutral: 'неутрална',
  positive: 'позитивна',
  negative: 'негативна'
}
