import React from 'react'
import FadeIn from 'react-fade-in'
import '../styles/Credits.css'

const CreditsPage = () => (
  <div className='credits-page'>
    <FadeIn>
      <div className='heading'><h4>Благодарим на тези прекрасни хора и организациите, които представляват за подкрепата!</h4></div>
      <ul>
        <li>
          <div>
            <b><a href='http://bultreebank.org/en/our-team/petya-osenova/' target='_blank' rel='noopener noreferrer'>Проф. д-р Петя Осенoва</a></b> - професор в катедра Български език, Факултет по славянски филологии, СУ „Св. Климент Охридски", работи в Институра за информационни и комуникационни технологии в секцията "Изкуствен интелект и езикови технологии", в екип с проф. д-р Кирил Симов. Преподава съвременен български език (морфология и синтаксис). Интересите й са в областта на формалната лингвистика, езиковите технологии и ресурси, компютърната лингвистика, електронното обучение, машинния превод. Специализирала е в областта на компютърната лингвистика като постдокторант в Тюбингенския университет, Германия (2003), Университета в Грьонинген, Холандия (2004) и като Фулбрайтов стипендиант в Станфордския университет в САЩ (2010).{' '}
            <b><i>Помага на АзБуки.ML с насоки и съвети от морфологична и синтактична гледна точка. Предоставя ни ресурси и материали от нейни изследвания и проучвания, а съвместните научни разработки с проф. д-р Кирил Симов са в основата на граматичния модул в платформата. Много от новите ни модели и подхода на работа са съобразени с нейните професионални насоки. <br />Благодарим ❤️, безценна сте!</i></b>
            <br /><br />
          </div>
        </li>
        <li>
          <div>
            <b><a href='https://hora.today/staff-view/denitsa-yoncheva/' target='_blank' rel='noopener noreferrer'>Деница Йончева</a></b> - Дени е завършила Софийската математическа гимназия. Бакалавъра си изкарва в Амстердам в учене на политика, право, психология и икономика. Интересува се от бизнес, изкуство, политика и пиене на бира с приятели. Обича да любопитства, да рисува, да пътува и да среща нови хора и съмишленици. Вярва, че най-добрият начин човек да научи нещо е като първо се провали и упорито следва тази философия в изпитните сесии.{' '}
            <b><i>Помага на АзБуки.ML в качеството си на data scientist: събира нови данни за нашите модели, работи по вече съществуващи данни от други източници, като ги почиства и организира в полезен за използване формат. Помага при брейнсторминга и удря по едно рамо с Python... <br />Благодарим ❤️, безценна сте!</i></b>
            <br /><br />
          </div>
        </li>
      </ul>
    </FadeIn>
  </div>
)

export default CreditsPage
