import React from "react";
import { Form, Button } from "react-bootstrap";
import "../styles/SpeechAccess.css";

class SpeechAccess extends React.Component {
  state = {
    text: "",
    key: "",
    voice: "BG-bg-male",
    loading: false
  };

  handleTextChange = ev => {
    this.setState({ text: ev.target.value });
  };

  handleKeyChange = ev => {
    this.setState({ key: ev.target.value });
  };

  selectVoice = ev => {
    this.setState({ voice: ev.target.value });
  };

  handleClick = () => {
    const remoteAPI = "https://azbuki-ml-speech.azurewebsites.net/api/SpeechSynthesizer";
    const { text, key, voice } = this.state;
    this.setState({ loading: true });

    let requestBody = { text: text, apiKey: key }
    if (voice == "ssml") {
      requestBody["ssml"] = true;
    } else {
      requestBody["voice"] = voice.replace("BG-bg-", "");
    }


    if (!this.state.loading) fetch(remoteAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
      .then(resp => {
        if (resp.status === 200) {
          resp.blob().then(blob => {
            this.setState({ loading: false });
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "output.mp3";
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        } else {
          resp.text().then(text => {
            alert(text);
            this.setState({ loading: false });
          });
          console.log({ result: "Bad response!", resp: resp });
        }
      })
      .catch(err => {
        alert("Error!");
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const { state, handleTextChange, handleKeyChange, handleClick, selectVoice } = this;

    return (
      <div className="speech-access-page">
        <div className="search-bar">
          <Form>
            <textarea
              value={state.text}
              onChange={handleTextChange}
              placeholder="Въведете текст"
              id="search-box-textarea"
            />
            <input
              style={{ width: "100%" }}
              value={state.key}
              onChange={handleKeyChange}
              placeholder="API KEY"
              type="text"
            />
            <Form.Control as="select" custom value={state.voice} onChange={selectVoice}>
              <option>BG-bg-male</option>
              <option>Q2hyaXN0b3BoZXI=</option>
              <option>BG-bg-female</option>
              <option>Q29yYQ==</option>
              <option>ssml</option>
            </Form.Control>
            <div>{state.loading && "Loading..."}</div>
            <div>
              <Button style={{ width: "100%" }} onClick={handleClick}>
                Прочит
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default SpeechAccess;
