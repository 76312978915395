import React from 'react'
import FadeIn from 'react-fade-in'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SubscribeModal from '../components/SubscribeModal'
import '../styles/ChooseProduct.css'

const ChooseProductPage = () => (
  <div className='product-page'>
    <FadeIn>
      <Container>
        <Row>
          <Col className='option-col zooming-element' xs={12} sm={6} lg={4}>
            <Link to='/grammar'>
              <div className='product-option'>
                <h3 className='product-option-heading'>Граматика</h3>
                <p className='product-option-description'>
                  Морфологичен (PoS) и депендентен анализ, синтактичен разбор, справки с речници и тн.
                </p>
              </div>
            </Link>
          </Col>
          <Col className='option-col zooming-element' xs={12} sm={6} lg={4}>
            <Link to='/sentiment'>
              <div className='product-option'>
                <h3 className='product-option-heading'>Анализ на настроението</h3>
                <p className='product-option-description'>
                  Определяне на позитивно/негативно/неутрално настроение, изваждане на експресивни думи и т.н.
                </p>
              </div>
            </Link>
          </Col>
          <Col className='option-col zooming-element' xs={12} sm={6} lg={4}>
            <Link to='/generation'>
              <div className='product-option'>
                <h3 className='product-option-heading'>Генерация на текст</h3>
                <p className='product-option-description'>
                  Автоматично попълване на форми, генериране на текстове по шаблон (Smart Suggestions) и т.н.
                </p>
              </div>
            </Link>
          </Col>
          <Col className='option-col zooming-element' xs={2} />
          <Col className='option-col zooming-element' xs={12} sm={6} lg={4}>
            <a href='https://huggingface.co/radi-cho/poetry-bg' target='_blank'>
              <div className='product-option'>
                <h3 className='product-option-heading'>Поезия</h3>
                <p className='product-option-description'>
                  Генерация на поетични текстове на български език с езиков модел от вида GPT-2.
                </p>
              </div>
            </a>
          </Col>
          <Col className='option-col zooming-element' xs={12} sm={6} lg={4}>
            <Link to='/speech'>
              <div className='product-option disabled'>
                <h3 className='product-option-heading'>Text-to-Speech</h3>
                <p className='product-option-description'>
                  Инструменти за подобряване на качеството и изразителността при TTS синтезатори.
                </p>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </FadeIn>
  </div>
)

export default ChooseProductPage
