import React from 'react';
import { Button } from 'react-bootstrap';
import ExamplesModal from '../components/ExamplesModal';
import { emotionScheme } from '../scripts/consts';
import '../styles/Sentiment.css'

const exampleTexts = [
  'Мразя да ми се карат по този начин.',
  'Не те мразя.',
  'Обичам да ям сладолед. Всеки ден си вземам.',
  'Не обичам да ям сладолед.',
  'Галактика е гравитационно свързана система от звезди, междузвезден газ и прах, плазма.',
];

class SentimentPage extends React.Component {
  state = {
    text: '',
    polaritylex: [],
    showPrefs: false,
    warning: null,
    emotion: null,
  };

  handleChange = ev => {
    this.setState({ text: ev.target.value });
  };

  fetchData = () => {
    const remoteAPI = 'https://us-central1-azbuki-ml.cloudfunctions.net/forwardApi';
    // const remoteAPI = 'http://0.0.0.0:5001/api';
    this.setState({ polaritylex: [], warning: null, emotion: null });

    fetch(`${remoteAPI}/sentiment`, {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({text: this.state.text })
    })
      .then(resp => {
        if (resp.status === 200) {
          resp.json().then(jsonResp => {
            if (jsonResp && jsonResp.length === 2) {
              if (jsonResp[0] < 0.5) {
                this.setState({ emotion: 'neutral' });
              } else if (jsonResp[1] < 0.5) {
                this.setState({ emotion: 'negative' });
              } else {
                this.setState({ emotion: 'positive' });
              }
            } else {
              this.setState({ warning: 'Възникна грешка. В момента се извършва техническа профилактика! Демото ще бъде достъпно отново съвсем скоро :)' })
            }
          });
        } else {
          console.log({ result: 'Bad response!', resp: resp });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ warning: 'Възникна грешка. В момента се извършва техническа профилактика! Демото ще бъде достъпно отново съвсем скоро :)' })
      })

      fetch(`${remoteAPI}/lex`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({text: this.state.text })
      })
      .then(resp => {
        if (resp.status === 200) {
          resp.json().then(jsonResp => this.setState({ polaritylex: typeof jsonResp[0] === 'object' ? jsonResp : null }));
        } else {
          console.log({ result: 'Bad response!', resp: resp });
        }
      })
  }

  openWord = word => {
    window.open(`https://rechnik.chitanka.info/w/${word}`, '_blank');
  }

  handleExample = text => {
    this.setState({ text: text }, () => {
      this.handleClick();
    });
  }

  handleClick = () => {
    this.fetchData();
  };

  render() {
    const { state, handleChange, handleClick } = this;
    const { polaritylex, emotion, text } = state;

    return (
      <div className='sentiment-page'>
        <div className='search-bar'>
          {state.warning && <div>{state.warning}<br /><br /></div>}
          <div style={{display: 'none'}}><textarea value={state.text} onChange={handleChange} placeholder='Въведете текст' id='search-box-textarea' /></div>
          <div>
            <ExamplesModal
              buttonCaption='Избери изречение...'
              buttonStyle={{ width: '100%', margin: '5px 0' }}
              test={this.handleExample}
              texts={exampleTexts} />
          </div>
          <div style={{display: 'none'}}><Button style={{ width: '100%' }} onClick={handleClick}>Анализ</Button></div>
          <div style={{fontSize: 24, margin: 10}}>{text}</div>
          {/* <Button
            variant='light'
            style={{ borderColor: '#c6c5c5', backgroundColor: '#e9e9e9' }}
            id='expand-prefs'
            onClick={this.showPrefs}>{state.showPrefs ? '˄' : '˅'}</Button> */}
        </div>
        {emotion && <>
          <hr />
          <div className='emotions'>
            <div className={`emotion ${emotion === 'positive' ? 'highlight-emotion' : ''}`}>
              <span role='img'>🙂</span>
            </div>
            <div className={`emotion ${emotion === 'neutral' ? 'highlight-emotion' : ''}`}>
              <span role='img'>😐</span>
            </div>
            <div className={`emotion ${emotion === 'negative' ? 'highlight-emotion' : ''}`}>
              <span role='img'>🙁</span>
            </div>
            <div className='emotion-descr'>Предполагаема емоция: {emotionScheme[emotion]}</div>
          </div>
          <hr />
        </>}
        {polaritylex && polaritylex.length && polaritylex[0]['count'] ? <h3>Негативни и обидни думи</h3> : <span />}
        {polaritylex && polaritylex.length && polaritylex[0]['count'] ?
          <div className='result-polaritylex'>
            {
              Object.keys(polaritylex[0]['dictionary']).map(
                (word, i) => <div key={i} className='word offensive-word' onClick={() => this.openWord(word)}>
                  {word}
                </div>
              )
            }
            <p>{JSON.stringify({
              'coefficient': Math.round((polaritylex[0]['coefficient'] + Number.EPSILON) * 100) / 100,
              'count': polaritylex[0]['count'],
              'unique_count': polaritylex[0]['unique_count']
            })}</p>
            <hr />
          </div> : <span />
        }
        {polaritylex && polaritylex.length && polaritylex[1]['count'] ? <h3>Позитивни думи</h3> : <span />}
        {polaritylex && polaritylex.length && polaritylex[1]['count'] ?
          <div className='result-polaritylex'>
            {
              Object.keys(polaritylex[1]['dictionary']).map(
                (word, i) => <div key={i} className='word positive-word' onClick={() => this.openWord(word)}>
                  {word}
                </div>
              )
            }
            <p>{JSON.stringify({
              'coefficient': Math.round((polaritylex[1]['coefficient'] + Number.EPSILON) * 100) / 100,
              'count': polaritylex[1]['count'],
              'unique_count': polaritylex[1]['unique_count']
            })}</p>
            <hr />
          </div> : <span />
        }
        <br /><br /><br />
      </div>
    );
  }
}

export default SentimentPage;
