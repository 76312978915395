import React from "react";
import { Button, Badge } from "react-bootstrap";
import ExamplesModal from "../components/ExamplesModal";
import { dictionaryScheme, posUniversalScheme, posBulScheme } from "../scripts/consts";
import "../styles/Grammar.css"

const exampleTexts = [
  "Аз обичам България защото е моята родина.",
  "Ясно е че децата които растат с домашни любимци се учат в ранна възраст да проявяват отговорно отношение.",
  "Карнавалните маски се изработват от картон дърво пластмаса и кожа и могат да представят различни характери.",
  "Запознах се с родителите които поканих на срещата и дълго си говорихме за оценките и за дисциплината на децата.",
];

class GrammarPage extends React.Component {
  state = {
    sent: "",
    grammar: [],
    punctuation: [],
    pos: [],
    hoverPosDetails: false,
    hoverDictionaryDetails: false,
    showDict: true,
    showLemmas: false,
    showLegend: false,
    predictCommas: true,
    showPrefs: false,
    warning: null,
  };

  handleChange = ev => {
    this.setState({ sent: ev.target.value });
  };

  handleKeyDown = ev => {
    if (ev.key === "Enter") {
      this.handleClick();
    }
  };

  fetchData = () => {
    const remoteAPI = "https://us-central1-azbuki-ml.cloudfunctions.net/forwardApi";
    // const remoteAPI = "http://0.0.0.0:5001/api";
    this.setState({ grammar: [], punctuation: [], warning: null });


    fetch(`${remoteAPI}/pos`, {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      method: "POST",
      body: JSON.stringify({text: this.state.sent})
    })
      .then(resp => {
        if (resp.status === 200) {
          resp.json().then(jsonResp => {
            this.setState({ pos: Array.isArray(jsonResp[0]) ? jsonResp[0] : [], showLegend: true });
            if (jsonResp.length > 1) {
              this.setState({
                warning: "Този анализ е предназначен само за едно изречение."
              })
            }
          });
        } else {
          console.log({ result: "Bad response!", resp: resp });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ warning: "Възникна грешка. В момента се извършва техническа профилактика! Демото ще бъде достъпно отново съвсем скоро :)" })
      })

    if (this.state.showDict) {
      fetch(`${remoteAPI}/sent`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({text: this.state.sent })
      })
        .then(resp => {
          if (resp.status === 200) {
            resp.json().then(jsonResp => this.setState({ grammar: jsonResp }));
          } else {
            console.log({ result: "Bad response!", resp: resp });
          }
        })
    }

    if (this.state.predictCommas) {
      fetch(`${remoteAPI}/pnct`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({text: this.state.sent })
      })
        .then(resp => {
          if (resp.status === 200) {
            resp.json().then(jsonResp => this.setState({ punctuation: jsonResp }));
          } else {
            console.log({ result: "Bad response!", resp: resp });
          }
        })
    }
  }

  handleClick = () => {
    this.fetchData();
    // more checks
  };

  openPoS = label => {
    window.open(`https://universaldependencies.org/u/pos/${label}.html`, "_blank");
  }

  openWord = word => {
    window.open(`https://rechnik.chitanka.info/w/${word}`, "_blank");
  }

  updateLegend = () => {
    this.setState({
      showLegend: !this.state.showLegend
    });
  }

  showPrefs = () => {
    this.setState({
      showPrefs: !this.state.showPrefs
    });
  }

  updatePunct = () => {
    this.setState({
      predictCommas: !this.state.predictCommas
    });
  }

  updateLemmas = () => {
    this.setState({
      showLemmas: !this.state.showLemmas
    })
  }

  updateGrammarDict = () => {
    this.setState({
      showDict: !this.state.showDict
    })
  }

  handleExample = text => {
    this.setState({ sent: text }, () => {
      this.handleClick();
    });
  }

  render() {
    const { state, handleChange, handleClick, handleKeyDown } = this;
    let punctItem = -1;

    return (
      <div className="grammar-page">
        <div className="search-bar">
          {state.warning && <div>{state.warning}</div>}
          <input value={state.sent} onChange={handleChange} onKeyDown={handleKeyDown} placeholder="Въведете изречение" id="search-box-input" />
          <Button onClick={handleClick}>Анализ</Button>
          <Button
            variant="light"
            style={{ borderColor: "#c6c5c5", backgroundColor: "#e9e9e9" }}
            id="expand-prefs"
            onClick={this.showPrefs}>{state.showPrefs ? "˄" : "˅"}</Button>
          <ExamplesModal
            buttonStyle={{ width: "70vw", margin: "5px 0", maxWidth: 750 }}
            note="Дадени са изречения от ДЗИ 12 клас без запетаи, за да се демонстрират способностите на платформата да поставя пунктуация в сложни изречения."
            test={this.handleExample}
            texts={exampleTexts} />
        </div>
        {
          state.showPrefs &&
          <div className="prefs">
            <label htmlFor="enable-pos-tagging">
              <input type="checkbox" id="enable-pos-tagging" checked disabled />
              <span>Морфологичен анализ</span>
              <Badge variant="secondary">beta</Badge>
            </label>
            <br />
            <label htmlFor="enable-grammar-dict">
              <input type="checkbox" id="enable-grammar-dict" checked={state.showDict} onChange={this.updateGrammarDict} />
              <span>Граматични признаци на думите</span>
              <Badge variant="secondary">beta</Badge>
            </label>
            <br />
            <label htmlFor="enable-lemmas">
              <input type="checkbox" id="enable-lemmas" checked={state.showLemmas} onChange={this.updateLemmas} />
              <span>Основни форми (lemmatisation)</span>
              <Badge variant="secondary">beta</Badge>
            </label>
            <br />
            <label htmlFor="enable-punctuation">
              <input type="checkbox" id="enable-punctuation" checked={state.predictCommas} onChange={this.updatePunct} />
              <span>Пунктуационни предложения</span>
              <Badge variant="secondary">alpha</Badge>
            </label>
            <br />
          </div>
        }
        {state.pos.length ? <h3>Морфологичен анализ</h3> : <span />}
        <div className="result-pos">
          {state.pos.map((word, i) => {
            punctItem++;
            return (
              <React.Fragment key={i}>
                {
                  state.punctuation && state.punctuation[punctItem] === ",COMMA"
                    ? <span id={`comma${punctItem++}`} title="PREDICTED ,COMMA" className="comma">,</span>
                    : ""
                }
                <div
                  className="word"
                  onClick={() => this.openPoS(word[1])}
                  style={{ background: posUniversalScheme[word[1]] ?? posUniversalScheme["*"] }}
                  onMouseOver={() => this.setState({ hoverPosDetails: i })}
                  onMouseOut={() => this.setState({ hoverPosDetails: false })}
                >
                  <span className="sp1">{word[0]}</span><br />
                  <div className="separator"></div>
                  <span className="sp2">{word[1]}</span>{" "}
                  <span className="sp3">({word[2]})</span>
                  {
                    this.state.showLemmas && <>
                      <div className="separator"></div>
                      <span className="sp4">{word[3]}</span>
                    </>
                  }

                  {state.hoverPosDetails === i && posBulScheme[word[2]] ? <div className="ttip">
                    <div>{posBulScheme[word[2]] ?? "Друго"}</div>
                  </div> : <span />}
                </div>
              </React.Fragment>);
          })}
        </div>
        <br />
        {state.grammar.length ? <h3>Граматичен речник</h3> : <span />}
        <div className="result-sent">
          {state.grammar.map((word, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className="word"
                  style={{
                    background: dictionaryScheme[word[7]]
                      ? (
                        word[3] && word[3].indexOf("Други форми") >= 0
                          ? `repeating-linear-gradient(
                              -45deg,
                              ${dictionaryScheme[word[7]][0]},
                              ${dictionaryScheme[word[7]][0]} 15px,
                              white 15px,
                              white 17px
                            )`
                          : dictionaryScheme[word[7]][0]
                      )
                      : dictionaryScheme["*"][0]
                  }}
                  onMouseOver={() => this.setState({ hoverDictionaryDetails: i })}
                  onMouseOut={() => this.setState({ hoverDictionaryDetails: false })}
                  onClick={() => { this.openWord(word[0]) }}
                >
                  <span>{word[0]}</span>
                  {state.hoverDictionaryDetails === i ? <div className="ttip">
                    <div><b>{dictionaryScheme[word[7]] ? dictionaryScheme[word[7]][1] : dictionaryScheme["*"][1]}</b><br /><span style={{ color: "grey" }}>({word[7]})</span></div>
                    <div>{
                      word[3]
                        ? word[3].split("\n").map((row, k) =>
                          <div style={k >= 1 ? { fontSize: 13, fontWeight: 700 } : {}} key={k}>
                            {row}
                          </div>
                        )
                        : <></>
                    }</div>
                  </div> : <span />}
                </div>
              </React.Fragment>);
          })}
        </div>
        <br /><br /><br />
        <div className="legend" style={state.showLegend ? { width: "98vw" } : { width: "auto" }} onClick={this.updateLegend}>
          {state.showLegend ?
            <>
              <div className="close-icon">x</div>
              <div className="l-row">
                <div className="l-color" style={{ background: "#18a1e0" }}></div>
                <div className="l-label">съществителни и числителни</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "#f7a5b3" }}></div>
                <div className="l-label">прилагателни</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "#86dc86" }}></div>
                <div className="l-label">местоимения</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "#ffca6a" }}></div>
                <div className="l-label">глаголни форми</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "cyan" }}></div>
                <div className="l-label">наречия</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "#d794ff" }}></div>
                <div className="l-label">частици, съюзи и т.н.</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "repeating-linear-gradient(-45deg, grey, grey 3px, white 3px, white 5px)" }}></div>
                <div className="l-label">многозначност</div>
              </div>
              <div className="l-row">
                <div className="l-color" style={{ background: "#de7a7a" }}></div>
                <div className="l-label">пунктуация (запетаи)</div>
              </div>
              {/* <div className="l-row">
                <div className="l-color" style={{ background: "#dcdcdc" }}></div>
                <div className="l-label">други</div>
              </div> */}
              <div className="l-label" style={{ fontSize: 10, color: "gray", marginTop: 5 }}>
                Внимание: Това е демонстративна версия на АзБуки.ML. Възможни са езикови и граматични грешки.
              </div>
            </> :
            <>&#9432;</>
          }
        </div>
      </div>
    );
  }
}

export default GrammarPage;
