import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/functions'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyCKV5YZP0ajru4zuEnV91maYF170iHzY3A',
    authDomain: 'azbuki-ml.firebaseapp.com',
    databaseURL: 'https://azbuki-ml.firebaseio.com',
    projectId: 'azbuki-ml',
    storageBucket: 'azbuki-ml.appspot.com',
    messagingSenderId: '831716614526',
    appId: '1:831716614526:web:41446de0eeb11ad532d32f',
    measurementId: 'G-67SG514993'
  })
}

export const database = firebase.database()
export const auth = firebase.auth()
export const auth_meta = firebase.auth
auth.setPersistence(auth_meta.Auth.Persistence.SESSION)

export const firestore = firebase.firestore()
export const functions = firebase.functions()
