import React from "react";
import { Button } from "react-bootstrap";
import ExamplesModal from "../components/ExamplesModal";
import "../styles/Generation.css"

const exampleTexts = [
  "Да бъдеш или...",
  "Който не работи...",
  "Едно мисли...",
];

const exampleLengths = {
  "Да бъдеш или...": 3,
  "Който не работи...": 4,
  "Едно мисли...": 4,
}

class GenerationPage extends React.Component {
  state = {
    text: "",
    warning: null,
    result: null,
    genAmount: 3
  };

  handleChange = ev => {
    this.setState({ text: ev.target.value });
  };

  handleAmountChange = ev => {
    this.setState({ genAmount: parseInt(ev.target.value) });
  };

  fetchData = () => {
    const remoteAPI = "https://us-central1-azbuki-ml.cloudfunctions.net/forwardApi";
    // const remoteAPI = "http://0.0.0.0:5001/api";
    this.setState({ warning: null, result: null });

    const { text, genAmount } = this.state;
    fetch(`${remoteAPI}/gen`, {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      method: "POST",
      body: JSON.stringify({text: text, amount: genAmount})
    })
      .then(resp => {
        if (resp.status === 200) {
          resp.json().then(jsonResp => {
            this.setState({ result: jsonResp });
          });
        } else {
          console.log({ result: "Bad response!", resp: resp });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ warning: "Възникна грешка. В момента се извършва техническа профилактика! Демото ще бъде достъпно отново съвсем скоро :)" })
      })
  }

  handleExample = text => {
    this.setState({ text: text, genAmount: exampleLengths[text] }, () => {
      this.handleClick();
    });
  }

  handleClick = () => {
    this.fetchData();
  };

  render() {
    const { state, handleChange, handleClick, handleAmountChange } = this;
    const { text, result, genAmount } = state;

    return (
      <div className="generation-page">
        <div className="search-bar">
          {state.warning && <div>{state.warning}<br /><br /></div>}
          <div>
            <textarea
              value={state.text}
              onChange={handleChange}
              placeholder="Въведете фраза или част от изречение, което да бъде допълнено"
              id="search-box-textarea" />
            <input
              style={{ width: "100%" }}
              value={genAmount}
              onChange={handleAmountChange}
              type="number" />
          </div>
          <div>
            <ExamplesModal
              buttonStyle={{ width: "100%", margin: "5px 0" }}
              test={this.handleExample}
              texts={exampleTexts} />
          </div>
          <div><Button style={{ width: "100%" }} onClick={handleClick}>Анализ</Button></div>
          <p>
            Вижте новата ни инициатива за генериране на поетични текстове <a href="https://instagram.com/izkustvo.ai">тук</a>.
          </p>
        </div>
        {result && <>
          <div className="result-generation">
            <div className="inputted-sent">{text}</div>
            {
              result.map(
                (word, i) => <div key={i} className="word positive-word" onClick={() => this.openWord(word)}>
                  {word}
                </div>
              )
            }
          </div>
        </>}
        <br /><br /><br />
      </div>
    );
  }
}

export default GenerationPage;
