import React from 'react'
import FadeIn from 'react-fade-in'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Partners from '../components/Partners'
import SubscribeModal from '../components/SubscribeModal'
import '../styles/About.css'

const AboutPage = () => (
  <div className='about'>
    <div className='heading'>
      <FadeIn>
        <div>AzBuki.ML е иновативна machine learning платформа - съвкупност от компютърни програми за обработка на българския език и слово.</div>
        <div className='head-actions'>
          <Button variant='dark' href='/demo'>Разгледай демо</Button>
          <Button variant='dark' href='/api'>API</Button>
          <SubscribeModal><Button variant='dark'>Получавай новини</Button></SubscribeModal>
          <Button variant='dark' href='/donate'>Подкрепи идеята</Button>
        </div>
      </FadeIn>
    </div>
    <div className='news-caption'><b>Новини:</b> Публикувахме научен доклад на тема "Efficient TOD Systems with Response Selection as an Auxiliary Task" в <a href="https://aclanthology.org/2022.icnlsp-1.2/">ACL Anthology</a>.</div>
    <div className='presentation'>
      <Container>
        <FadeIn delay={500} transitionDuration={750}>
          <Row>
            <Col className='zooming-element' md={7}>
              Идеята е да се приложат на практика дълбоки невронни мрежи и лингвистични алгоритми, извличащи смисъл, контекст и граматични признаци на речта.
              Подобни технологии са силно развити за езици като английски и немски, но няма подобен лесно достъпен продукт за българския език.
            </Col>
            <Col className='zooming-element' md={5}><img alt='Graphics' src='https://image.freepik.com/free-vector/chatbot-artificial-intelligence-abstract-concept-illustration-artificial-intelligence-chatbot-service-interactive-support-machine-learning-natural-language-processing_335657-339.jpg' width='100%' /></Col>
          </Row>
          <Row>
            <Col className='zooming-element' md={5}><img alt='Graphics' src='https://image.freepik.com/free-vector/tiny-woman-using-mobile-assistant-with-chatbot_74855-7883.jpg' width='100%' /></Col>
            <Col className='zooming-element' md={7}>
              Целта ни е да надградим съществуващи частични проучвания и научни разработки в сферата, за да създадем лесно достъпни инструменти (APIs) за разработчици, както и множество first-party потребителски програми.
            </Col>
          </Row>
          <Row>
            <Col className='zooming-element' md={7}>
              Сигурни сме, че те могат да намерят употреба, както в образованието и науката така и в онлайн бизнеса и медиите.
            </Col>
            <Col className='zooming-element' md={5}><img alt='Graphics' src='https://image.freepik.com/free-vector/business-team-discussing-ideas-startup_74855-4380.jpg' width='100%' /></Col>
          </Row>
        </FadeIn>
      </Container>
    </div>
    <FadeIn delay={500} transitionDuration={750}>
      <br />
      <hr />
      <Container style={{ textAlign: 'center' }}>
        <h3 className='usecases-caption'>Ако имате интерес да изпозвате нашия софтуер в собствен продукт:</h3><br />
        <Button className='zooming-element' style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: 26 }} variant='success' href='/api'>Вижте АзБуки.ML API</Button>
      </Container>
      <Partners />
      <div className='creator'>
        <Container>
          <Row>
            <Col md={3}><img alt='Graphics' src='https://pbs.twimg.com/profile_images/1591179897124306949/X_5JiAVw_400x400.jpg' width='100%' /></Col>
            <Col md={9}>
              <span style={{ fontSize: 26 }}>
                <a href='https://www.linkedin.com/in/radostin-cholakov-bb4422146/' target='_blank' rel='noopener noreferrer'>
                  Радостин Чолаков
                </a> - създател на АзБуки.ML<br />
              </span>
              <span>
                Радостин Чолаков се занимава с разработка на софтуерни проекти и изследователска дейност. Като ученик е печелил множество национални състезания по математика и информационни технологии, както и международни научни конкурси (<a href="https://uspelite.bg/radostin-cholakov-vsyako-postijenie-e-stoinostno-tai-kato-e-stapka-napred-v-izrastvaneto-mi-i-osmisl...-1">ISEF'22 и EUCYS'22</a>). Носител на награди "Джон Атанасов" за научната си дейност и "БАИТ" за проекта АзБуки.ML. Част от селекцията на Forbes България "30 под 30". Съорганизатор на няколко технологични събития, както и <a href="https://developers.google.com/community/experts/directory/profile/profile-radostin-cholakov">Google Developer Expert</a> за машинно обучение.
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='info-contact'><a style={{ color: 'black', fontWeight: 'bold' }} href='mailto:rsg.group.here@gmail.com'>Пиши ни</a> за повече информация и въпроси!</div>
      <Container className='fab'>
        <Row>
          <Col md={1} className='fab-logo-col'><img src='https://bcnl.org/uploadfiles/images/abf.png' /></Col>
          <Col md={11}>
            Подкрепа за АзБуки.ML е осигурена от Фондация „Америка за България“. Изявленията и мненията, изразени тук, принадлежат единствено на екипа на АзБуки.ML и не отразяват непременно вижданията на Фондация „Америка за България“ или нейните партньори. Фондация „Америка за България“ не носи отговорност за разработените продукти и/или технологии, нито за тяхното приложение.
          </Col>
        </Row>
      </Container>
    </FadeIn>
  </div>
)

export default AboutPage
