import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../styles/Partners.css'

const Partners = () => (
  <>
    <br /><hr />
    <h2 className='partners-caption'>Партньори:</h2>
    <br />
    <Container>
      <Row>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='http://www.bas.bg/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/baslogo.jpg' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.facebook.com/SofiaGoogleDevelopmentGroup' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/gdglogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='http://bultreebank.org/en/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/bultreebanklogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://play.google.com/store/apps/details?id=com.rsg.anygoal' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/anygoallogo1.png' alt='logo' width='85%' />
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://hora.today/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/horatodaylogo.jpg' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://smileforafrica.eu/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/smileforafricalogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.iict.bas.bg/EN/index.html' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/iictlogo.gif' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='http://bridgethroughcenturies.com/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/bridgelogo.png' alt='logo' width='85%' />
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.uni-sofia.bg/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/sulogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.axosoft.com/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/axosoftlogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.gitkraken.com/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/gitkrakenlogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://try.digitalocean.com/developer-cloud/' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/DO_Logo_Horizontal_Blue.png' alt='logo' width='90%' />
          </a>
        </Col>
      </Row>
    </Container>
    <br /><hr />
    <h2 className='media-caption'>В медиите:</h2>
    <Container>
      <Row>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.youtube.com/watch?v=S1ohfuz3kqk' rel='noreferrer noopener' target='_blank'>
            <img src='https://nstatic.nova.bg/public/pics/nova/news/980x551_1466070471.jpg' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.youtube.com/watch?v=T-8y-BkK8_o' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/seveneighttvlogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.youtube.com/watch?v=OKSMAhs56EY' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/bulgariaonairlogo.png' alt='logo' width='95%' />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} className='partner-logo zooming-element'>
          <a href='https://www.facebook.com/AzBuki.ML/videos/850636825479872' rel='noreferrer noopener' target='_blank'>
            <img src='https://storage.googleapis.com/azbuki_01/static/partners/bntlogo.svg' alt='logo' width='95%' />
          </a>
        </Col>
        {/* <Col xs={6} sm={6} md={3} className="partner-logo">
          <a href="https://www.actualno.com/interview/da-syzdadesh-izkustven-intelekt-samo-na-14-godini-video-news_1523327.html" rel="noreferrer noopener" target="_blank">
            <img src="https://storage.googleapis.com/azbuki_01/static/partners/actualnologo.png" alt="logo" width="85%" />
          </a>
        </Col> */}
      </Row>
    </Container>
  </>
)

export default Partners
