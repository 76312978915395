import React, { useState } from 'react'
import { Modal, Button, Table } from 'react-bootstrap'

const ExamplesModal = (props) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button style={props.buttonStyle} variant='dark' onClick={handleShow}>{props.buttonCaption || 'Виж примерни изречения'}</Button>

      <Modal show={show} size='lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Примерни текстове</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.note && <div style={{ textAlign: 'center', marginBottom: 7 }}>{props.note}</div>}
          <Table bordered hover>
            <thead>
              <tr>
                <th>Текст</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.texts.map((text, i) =>
                <tr key={i}>
                  <td style={{ verticalAlign: 'middle' }}>{text}</td>
                  <td style={{ width: 1 }}><Button onClick={() => { props.test(text); handleClose() }}>Тествай</Button></td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Затваряне
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ExamplesModal
