import React, { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { firestore, auth, auth_meta, functions } from '../scripts/firebase'
import '../styles/ApiDashboard.css'

const uiConfig = {
  signInOptions: [auth_meta.EmailAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
}

function signOut (setIsSignedIn, setApiKeys, setIsUpdatingKey) {
  setIsUpdatingKey(true)
  setIsSignedIn(false)

  auth.signOut().then(_ => {
    setApiKeys([])
    setIsUpdatingKey(false)
  })
}

function fetchApiKeys (isSignedIn, setApiKeys, currentService) {
  if (!isSignedIn) return
  firestore
    .collection(currentService)
    .where('users', 'array-contains', auth.currentUser.uid)
    .get()
    .then(querySnapshot => {
      const result = []
      querySnapshot.forEach(doc => result.push(doc))
      if (result.length === 0) alert('No keys for the selected API.')
      setApiKeys(result)
    })
    .catch(error => {
      console.log('Error getting documents: ', error)
    })
}

function updateApiKey (
  key,
  isUpdatingKey,
  setIsUpdatingKey,
  isSignedIn,
  setApiKeys,
  currentService
) {
  const addMessage = functions.httpsCallable('updateApiKey')
  if (!isUpdatingKey) {
    const confirmationCode = Math.floor(
      100000 + Math.random() * 899999
    ).toString()
    if (
      prompt('Please enter the confirmation code: ' + confirmationCode) !==
      confirmationCode
    ) {
      alert('Wrong code!')
      return
    }

    setIsUpdatingKey(true)

    addMessage({ apiKey: key, service: currentService })
      .then(result => {
        if (result.data && result.data.apiKey) { fetchApiKeys(isSignedIn, setApiKeys, currentService) }
        setIsUpdatingKey(false)
      })
      .catch(e => {
        console.log(e)
        setIsUpdatingKey(false)
      })
  }
}

function copyKey (key) {
  navigator.clipboard.writeText(key).then(function () {
    console.log('Async: Copying to clipboard was successful!')
    alert('Copied!')
  }, function (err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function selectService (ev, setCurrentService, setApiKeys) {
  setCurrentService(ev.target.value)
  setApiKeys([])
}

function ApiDashboard () {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [apiKeys, setApiKeys] = useState([])
  const [isUpdatingKey, setIsUpdatingKey] = useState(false)
  const [currentService, setCurrentService] = useState('speech')

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
      setIsSignedIn(!!user)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (!isSignedIn) {
    return (
      <div className='api-dashboard'>
        <h1>АзБуки.ML APIs dashboard</h1>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </div>
    )
  }

  return (
    <div className='api-dashboard'>
      <h1>АзБуки.ML APIs dashboard</h1>
      <p>{`Signed in as ${auth.currentUser.displayName} <${auth.currentUser.email}>`}</p>
      <div className='actions'>
        <Button onClick={() => signOut(setIsSignedIn, setApiKeys, setIsUpdatingKey)}>Sign-out</Button>
        <Form.Control as='select' value={currentService} onChange={(ev) => selectService(ev, setCurrentService, setApiKeys)}>
          <option value='speech'>Speech API</option>
          <option value='processing'>Processing API</option>
        </Form.Control>
        <Button onClick={() => fetchApiKeys(isSignedIn, setApiKeys, currentService)}>
          Show my API keys
        </Button>
        {apiKeys.length ? (
          <Button onClick={() => setApiKeys([])}>Hide</Button>
        ) : (
          <span />
        )}
      </div>
      <div className='keys'>
        {apiKeys.map((key, i) => {
          const keyId = key.id
          const keyData = key.data()
          const { label, totalCount, totalLimit, monthlyLimit, totalReqLimit, monthlyReqLimit } = keyData
          const monthlyCounts = Object.keys(keyData).filter(m =>
            m.includes(new Date().getFullYear().toString())
          )

          return (
            <Card key={i}>
              <Card.Header as='h5'>{label || `API KEY ${i + 1}`}</Card.Header>
              <Card.Body>
                <Card.Text className='card-key' onClick={() => copyKey(keyId)}>
                  {keyId}
                </Card.Text>
                <div>
                  <div>Total usage: {totalCount || 0}</div>
                  {monthlyCounts.map(m => (
                    <div key={m}>
                      {m}: {keyData[m] || 0}
                    </div>
                  ))}
                </div>
                <br />
                {!(totalLimit || monthlyLimit) ? (
                  <div>There are no characters limits.</div>
                ) : (
                  <div>
                    <div>
                      Total chars limit: {totalLimit || Number.MAX_SAFE_INTEGER}
                    </div>
                    <div>
                      Monthly chars limit: {monthlyLimit || (totalLimit || Number.MAX_SAFE_INTEGER)}
                    </div>
                  </div>
                )}
                {currentService === 'processing'
                  ? <>
                    {!(totalReqLimit || monthlyReqLimit)
                      ? <div>There are no request limits.</div>
                      : (
                        <div>
                          <div>
                            Total requests limit: {totalReqLimit || Number.MAX_SAFE_INTEGER}
                          </div>
                          <div>
                            Monthly requests limit: {monthlyReqLimit || (totalReqLimit || Number.MAX_SAFE_INTEGER)}
                          </div>
                        </div>
                        )}
                  </>
                  : <span />}
                <br />
                <Button
                  onClick={() =>
                    updateApiKey(
                      keyId,
                      isUpdatingKey,
                      setIsUpdatingKey,
                      isSignedIn,
                      setApiKeys,
                      currentService
                    )}
                  disabled={isUpdatingKey}
                  variant='primary'
                >
                  REVOKE ACCESS & GENERATE NEW
                </Button>
              </Card.Body>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default ApiDashboard
