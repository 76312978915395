import React from 'react'
import FadeIn from 'react-fade-in'
import { Container, Row, Col } from 'react-bootstrap'
import '../styles/Contacts.css'

const ContactsPage = () => (
  <div className='contacts-page'>
    <FadeIn>
      <Container>
        <Row>
          <Col>
            <ul className='contacts'>
              <FadeIn delay={500} transitionDuration={750}>
                <li className='zooming-element'>E-mail: <a href='mailto:rsg.group.here@gmail.com'>rsg.group.here@gmail.com</a></li>
                <li className='zooming-element'>LinkedIn: <a href='https://www.linkedin.com/in/radostin-cholakov-bb4422146/'>Radostin Cholakov</a></li>
                <li className='zooming-element'>Facebook: <a href='https://www.facebook.com/AzBuki.ML'>AzBuki.ML</a> и <a href='https://www.facebook.com/radi.cho.profile'>Radi Cho</a></li>
              </FadeIn>
            </ul>
          </Col>
          <Col>
            <img src='https://storage.googleapis.com/azbuki-ml.appspot.com/5964.jpg' id='placeholder-image' />
          </Col>
        </Row>
      </Container>
    </FadeIn>
  </div>
)

export default ContactsPage
