import React, { useState } from 'react'
import { database } from '../scripts/firebase'
import { Modal, Button } from 'react-bootstrap'

const SubscribeModal = (props) => {
  const [email, updateEmail] = useState('')
  const [validInput, updateInputValidity] = useState(true)
  const [show, setShow] = useState(false)

  const handleSubscribe = () => {
    if (email.trim === '' || !validInput) {
      alert('Not valid input fields!')
    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    if (email && email.trim !== '' && validInput) {
      try {
        database.ref('email-listing').push({ email: email })
      } catch (e) {
        console.log(e)
      }
    }
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const handleChange = (field) => {
    const { value } = field.target
    updateEmail(value)

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(value)) {
      updateInputValidity(true)
    } else {
      updateInputValidity(false)
    }
  }

  return (
    <>
      <span onClick={handleShow}>
        {props.children}
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.upcomingFeature ? 'Очаквайте скоро :)' : 'Абонирайте се за новини'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.upcomingFeature && <p>Тази функционалност на АзБуки.ML все още се разработва. Абонирайте се за новини, както и ако искате да се присъедините към нашата early access програма и да получите ранен достъп до непубликувани ресурси!</p>}
          <input placeholder='you@example.com' type='email' onChange={handleChange} id='email-subscribe-box' />
          {!validInput && <p style={{ color: 'red' }}>Моля въведете валиден имейл адрес</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Затваряне
          </Button>
          <Button variant='primary' onClick={handleSubscribe}>
            Абониране
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SubscribeModal
