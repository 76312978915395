import React from 'react'
import FadeIn from 'react-fade-in'
import { Container, Row, Col, Button } from 'react-bootstrap'
import '../styles/Donate.css'

class DonatePage extends React.Component {
  render () {
    return (
      <div className='donate-page'>
        <FadeIn delay={500} transitionDuration={750}>
          <div className='donate-box'>
            <h1>Подкрепете идеята, като дарите колкото решите! Средствата ще бъдат използвани за развиване на алгоритмите, хостване на уебсайта и изчисленията в облака.</h1>
            <a href='https://paypal.me/RSGChess' target='_blank' rel='noreferrer noopener'>
              <img className='zooming-element' src='https://hancockhumane.org/resources/PayPal.jpg?timestamp=1532034679845' alt='Click to donate' id='paypal-donate-image' />
            </a>
            <br /><br />
            <div>
              <Button href='http://github.com/sponsors/radi-cho' variant='dark'>Подкрепете ни в GitHub Sponsors</Button>
            </div>
          </div>
          <div className='creator'>
            <Container>
              <Row>
                <Col md={3}><img alt='Graphics' src='https://pbs.twimg.com/profile_images/1591179897124306949/X_5JiAVw_400x400.jpg' width='100%' /></Col>
                <Col md={9}>
                  <span style={{ fontSize: 26 }}>
                    <a href='https://www.linkedin.com/in/radostin-cholakov-bb4422146/' target='_blank' rel='noopener noreferrer'>
                      Радостин Чолаков
                    </a> - създател на АзБуки.ML<br />
                  </span>
                  <span>
                    Радостин Чолаков се занимава с разработка на софтуерни проекти и изследователска дейност. Като ученик е печелил множество национални състезания по математика и информационни технологии, както и международни научни конкурси (<a href="https://uspelite.bg/radostin-cholakov-vsyako-postijenie-e-stoinostno-tai-kato-e-stapka-napred-v-izrastvaneto-mi-i-osmisl...-1">ISEF'22 и EUCYS'22</a>). Носител на награди "Джон Атанасов" за научната си дейност и "БАИТ" за проекта АзБуки.ML. Част от селекцията на Forbes България "30 под 30". Съорганизатор на няколко технологични събития, както и <a href="https://developers.google.com/community/experts/directory/profile/profile-radostin-cholakov">Google Developer Expert</a> за машинно обучение.
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </FadeIn>
      </div>
    )
  }
}

export default DonatePage
