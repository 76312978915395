import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import 'bootstrap/dist/css/bootstrap.min.css'

// import { MainIcon } from "./components/MainIcon";
import './styles/App.css'
import GrammarPage from './pages/Grammar.js'
import AboutPage from './pages/About.js'
import { MainIcon } from './components/MainIcon'
import DonatePage from './pages/Donate'
import ContactsPage from './pages/Contacts'
import ChooseProductPage from './pages/ChooseProduct'
import SentimentPage from './pages/Sentiment'
import CreditsPage from './pages/Credits'
import ApiPage from './pages/Api'
import SpeechPage from './pages/Speech'
import GenerationPage from './pages/Generation'
import SpeechAccessPage from './pages/SpeechAccess'
import ApiDashboardPage from './pages/ApiDashboard'
import EduPage from './pages/Edu'
import AudioReadingPage from './pages/AudioReading'
import NotFoundPage from './pages/NotFound'
import Sunset from './Sunset.js'

function App () {
  return (
    <Router>
      <Navbar bg='light' expand='md'>
        <Navbar.Brand>
          <Link to='/' className='router-link' style={{ color: 'black' }}>
            <MainIcon />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            <Nav.Item>
              <Link className='router-link' to='/api-dashboard'>API Портал</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className='router-link' to='/speech-access'>Аудио услуги</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className='router-link' to='/edu'>АзБуки.edu</Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Switch>
        <Route path='/' exact><Sunset /></Route>
        <Route path='/grammar'><GrammarPage /></Route>
        <Route path='/sentiment'><SentimentPage /></Route>
        <Route path='/generation'><GenerationPage /></Route>
        <Route path='/products'><ChooseProductPage /></Route>
        <Route path='/demo'><ChooseProductPage /></Route>
        <Route path='/about'><AboutPage /></Route>
        <Route path='/donate'><DonatePage /></Route>
        <Route path='/contacts'><ContactsPage /></Route>
        <Route path='/credits'><CreditsPage /></Route>
        <Route path='/api'><ApiPage /></Route>
        <Route path='/speech'><SpeechPage /></Route>
        <Route path='/speech-access'><SpeechAccessPage /></Route>
        <Route path='/api-dashboard'><ApiDashboardPage /></Route>
        <Route path='/edu' exact><EduPage /></Route>
        <Route path='/edu/audio-read' exact><AudioReadingPage /></Route>
        <Route path='/*'><NotFoundPage /></Route>
      </Switch>
      <div style={{ height: '30px' }} />
      <div className='footer'>
        Copyright © 2020-2024 AzBuki.ML (Radostin Cholakov); All Rights Reserved.
      </div>
    </Router>
  )
}

export default App
