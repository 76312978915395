import React from 'react'
import FadeIn from 'react-fade-in'
import { Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../styles/Speech.css'

const sources = [
  'Генерирано с разпространени български синтезатори: ',
  ['BG_W.mp3', 'BG_M.mp3'],
  'Генерирано с помощта на Google Cloud, Google Translate и др.: ',
  ['Google_W.mp3', 'Google_M.mp3'],
  'След допълнителната обработка на АзБуки.ML: ',
  ['AzBuki_W.mp3', 'AzBuki_M.mp3']
]

const SpeechPage = () => (
  <div className='speech-page'>
    <FadeIn>
      <h2>Прочит на текст с човешки глас от АзБуки.ML!</h2>
      <div className='speech-descr'>
        С помощта на невронните мрежи и изкуствения интелект изградихме нов инструмент, който да подобрява работата на звуковите синтезатори за българския език! Така те звучат много реалистично, правят минимални грешки, а границата между роботизиран и човешки глас се размива!
      </div>
      <Button className='zooming-element' style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: 26 }} variant='warning' href='mailto:rsg.group.here@gmail.com?body=Съобщение&subject=АзБуки.ML'>Свържете се с АзБуки.ML</Button>
      <br /><br />
      <h2>Вижте демо:</h2>
      <div className='demo-table'>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Жена</th>
              <th>Мъж</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2} className='demo-caption'>
                <i>БЗНС - Българският земеделски народен съюз е земеделско политическо движение в България. Съюзът е създаден през 1899 година като т.нар. професионална организация на селяните, но скоро се превръща в политическа партия, чието влияние постепенно нараства.</i>
              </td>
            </tr>
            {sources.map((el, i) => (
              <tr key={i}>
                {i % 2 ? el.map((url, j) => (
                  <td key={j}>
                    <audio controls>
                      <source src={'https://storage.googleapis.com/azbuki_01/static/' + url} type='audio/mpeg' />
                      Your browser does not support the audio element.
                    </audio>
                  </td>
                )) : <td colSpan={2}><b>{el}</b></td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <h2>Каквo са гласовите синтезатори?</h2>
      <div className='speech-paragraph'>
        Накратко: Инструменти, които правят прочит на даден текст с човешки глас! Подобен софтуер е силно развит за езици като английския. В България също съществуват такива синтезатори, но при тях гласът звучи твърде роботизиран, прави грешки в прочита на абревиатури, съкращения и имена.
      </div>
      <br />
      <h2>Какви са приложенята?</h2>
      <div className='speech-paragraph'>
        Добре работещ и естествено звучащ синтезатор на човешка реч може да има хиляди приложения! Ето някои:
        <ul>
          <li>Все повече западни медии интегрират синтезатори в своите уебсайтове, като така позволяват на потребителите да “чуят“ статиите им. Това повишава доверието на редовните читатели, привлича нови - например такива със зрителни проблеми, както и пести време в забързаното ежедневие.</li>
          <li>Чатботовете са част от все повече онлайн платформи. Освен асистенти, които могат да си “чатят” с потребителите, компаниите вече масово добавят и функционалността дигиталните помощници да могат да говорят с човешки глас.</li>
        </ul>
      </div>
      <h2>Какво предлагаме?</h2>
      <div className='speech-paragraph'>
        С помощта на изкуствения интелект и невронните мрежи, АзБуки.ML предоставя нов инструмент, който обработва българските текстове преди и след синтеза на звук от вече съществуващи синтезатори. Така се постигат най-реалистичните до момента резултати за нашия език!<br /><br />
        От подобен реалистичен гласов прочит вече могат да се възползват родните медии, уебсайтове, блогове и т.н., за да генерират звуков прочит на своите статии; компании, разработващи умни асистенти, за да интегрират българска реч; или всеки друг, заинтересуван от text-to-speech!<br /><br />
        С достъп до нашия API интерфейс ще можете да генерирате реалистичен звуков прочит на български текстове! <Link to='/contacts'>Свържете се с нас</Link> за повече подробности.
      </div>
      <iframe title='Facebook video' src='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FAzBuki.ML%2Fvideos%2F313770240350127%2F&show_text=false&width=560' width='560' height='314' style={{ border: 'none', overflow: 'hidden', maxWidth: '100%' }} scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share' allowFullScreen='true' />
    </FadeIn>
  </div>
)

export default SpeechPage
