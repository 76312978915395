import React, { useState } from 'react'
import '../styles/AudioReading.css'

const listWords = [
  ['ко-та-рак', 'https://image.freepik.com/free-photo/readhead-cat-sitting_181624-21415.jpg', ['all', 'animals']],
  ['па-те', 'https://image.freepik.com/free-vector/rubber-duck-baby-shower-card_24908-60190.jpg', ['all', 'animals']],
  ['ку-че', 'https://image.freepik.com/free-photo/long-haired-brown-dog-walking-with-open-mouth_1194-1094.jpg', ['all', 'animals']],
  ['миш-ка', 'https://image.freepik.com/free-vector/sticker-design-with-cute-mouse-isolated_1308-59360.jpg', ['all', 'animals']],
  ['дра-кон', 'https://image.freepik.com/free-photo/greet-final-battle-alone-illustration_456031-4.jpg', ['all', 'animals']],
  ['ли-си-ца', 'https://images.vexels.com/media/users/3/151733/isolated/lists/456d96033f3c3783bd1ea0c549969784-cute-fox-cartoon-design.png', ['all', 'animals']],
  ['меч-ка', 'https://w7.pngwing.com/pngs/938/806/png-transparent-brown-bear-cartoon-brown-bear-material-mammal-brown-animals.png', ['all', 'animals']],
  ['при-ро-да', 'https://i.pinimg.com/736x/54/f6/fe/54f6fe85a42a39b6e57d2008cf18964f.jpg', ['all']],
  ['ро-зо-во', 'https://www.fomei.com/ew/ew_images/image?EwImage=7c77c457-35d3-41fd-a10a-5710b2fb0be5&Filter=31ebf35d-0c3b-492e-b325-c3c536387844', ['all', 'colors']],
  ['пор-то-кал', 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Orange-Fruit-Pieces.jpg/1280px-Orange-Fruit-Pieces.jpg', ['all', 'fruits']],
  ['ди-ня', 'https://www.gardeningknowhow.com/wp-content/uploads/2021/05/whole-and-slices-watermelon.jpg', ['all', 'fruits']],
  ['ли-мон', 'https://media.healthyfood.com/wp-content/uploads/2017/03/What-to-do-with-lemons.jpg', ['all', 'fruits']],
  ['ки-ви', 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Kiwi_aka.jpg', ['all', 'fruits']],
  ['а-во-ка-до', 'https://solidstarts.com/wp-content/uploads/introducing-avocado-to-babies.jpg', ['all', 'fruits']],
  ['я-го-да', 'https://www.himalaya.bg/userfiles/editor/image/1product-packshot-strawberrie-558x600.jpg', ['all', 'fruits']],
  ['чер-ве-но', 'https://image.freepik.com/free-vector/red-pixel-pattern-background_1409-1285.jpg', ['all', 'colors']],
  ['чер-но', 'https://image.freepik.com/free-photo/old-black-background-grunge-texture-dark-wallpaper-blackboard-chalkboard-room-wall_1258-28312.jpg', ['all', 'colors']],
  ['ка-фя-во', 'https://www.dalstonmillfabrics.co.uk/pub/media/catalog/product/cache/1313879062af4fe4b91d2ab2cd3e697f/_/l/_l_i_lin11_89.jpg', ['all', 'colors']],
  ['зе-ле-но', 'https://image.freepik.com/free-photo/luxury-plain-green-gradient-abstract-studio-background-empty-room-with-space-your-text-picture_1258-63606.jpg', ['all', 'colors']],
  ['джу-дже', 'https://shturo.com/wp-content/uploads/2016/06/gradinsko-djudje-hranilka-za-ptichki-04.jpg', ['all']],
  ['че-ре-ша', 'https://image.freepik.com/free-photo/red-tasty-fruit-background-closeup_1417-429.jpg', ['all', 'fruits']]
]

const playAudio = (txt, callback) => {
  txt = txt.replaceAll('-', '')
  const audio = new Audio('/edu-speech/' + txt + '.mp3')
  audio.ontimeupdate = e => {
    if (e.target.currentTime >= e.target.duration - 0.800) {
      callback()
      e.target.pause()
      e.target.ontimeupdate = null
    }
  }

  audio.play()
}

const readPart = (word, partId, setPartId, playing, setPlaying) => {
  setPartId(partId + 1)
  if (playing && partId + 1 >= 0 && partId + 1 < word.length) {
    playAudio(word[partId + 1].replace('-', ''), () => {
      readPart(word, partId + 1, setPartId, playing, setPlaying)
    })
  }

  if (partId + 2 > word.length) {
    playAudio(word.join(''), () => setPlaying(false))
    setPartId(-1)
  }
}

const randomWord = (category, wordIndex) => {
  const currentList = listWords.filter(x => x[2].includes(category))
  const selectedWord = currentList[wordIndex % currentList.length]
  // const selectedWord = currentList[Math.floor(Math.random() * currentList.length)]
  return [selectedWord[0].replaceAll('-', '-.').split('.'), selectedWord[1]]
}

const readWord = (word, playing, setPlaying, setPartId) => {
  if (playing) return
  setPlaying(true)
  readPart(word, -1, setPartId, true, setPlaying)
}

const nextWord = (setWord, readWord, setPlaying, setPartId, category, wordIndex, setIndex) => {
  const currentWord = randomWord(category, wordIndex + 1)
  setIndex(wordIndex + 1)

  setWord(currentWord)
  readWord(currentWord[0], false, setPlaying, setPartId)
}

const AudioReading = () => {
  const [playing, setPlaying] = useState(false)
  const [partId, setPartId] = useState(-1)
  const [initialized, setInitialized] = useState(false)
  const [category, setCategory] = useState('all')
  const [currentWord, setWord] = useState(randomWord(category, 0))
  const [wordIndex, setIndex] = useState(0)

  return (
    <div className='audio-read'>
      {
        initialized
          ? <>
            <div className='reading-card' onClick={() => readWord(currentWord[0], playing, setPlaying, setPartId)}>
              <div>
                <img src={currentWord[1]} />
              </div>
              <div className='word'>
                {
                  currentWord[0].map(
                    (part, i) =>
                      <div
                        key={i}
                        className='to-be-read'
                        style={{ background: (partId === i ? '#82c5ff' : 'white') }}
                      >
                        {part}
                      </div>
                  )
                }
              </div>
            </div>
            <div
              id='next-button'
              onClick={() => playing ? console.log('Please wait!') : nextWord(setWord, readWord, setPlaying, setPartId, category, wordIndex, setIndex)}
              style={{ color: playing ? 'gray' : 'black', cursor: playing ? 'no-drop' : 'pointer' }}
            >
              {'Напред >'}
            </div>
            <div
              id='exit-button'
              onClick={() => playing ? console.log('Please wait!') : setInitialized(false)}
              style={{ color: playing ? 'gray' : 'black', cursor: playing ? 'no-drop' : 'pointer' }}
            >
              {'Изход'}
            </div>
          </>
          : <div className='begin-actions'>
            <select value={category} onChange={ev => setCategory(ev.target.value)}>
              <option value='all'>Всички</option>
              <option value='colors'>Цветове</option>
              <option value='animals'>Животни</option>
              <option value='fruits'>Плодове</option>
              {/* <option value='other'>Други</option> */}
            </select>
            <div
              id='begin-button'
              onClick={() => {
                const word = randomWord(category, 0)
                setIndex(0)
                setWord(word)
                setInitialized(true)
                readWord(word[0], playing, setPlaying, setPartId)
              }}
            >
              Старт
            </div>
          </div>
      }
    </div>
  )
}

export default AudioReading
