import React from 'react'
import FadeIn from 'react-fade-in'
import { Accordion, Card, Button } from 'react-bootstrap'
import '../styles/Edu.css'

const EduPage = () => (
  <div className='edu-page'>
    <FadeIn>
      <div class='logo-header'>
        <img className='zooming-element' src='https://storage.googleapis.com/azbuki_01/static/AzBuki_Edu_Cap2.png' id='edu-logo' alt='AzBuki.edu Logo' />
      </div>

      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='0'>
              Помагала за най-малките
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Аудио четене на срички</Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>Флаш карти с думи на срички</Card.Subtitle>
                  <Card.Text>
                    Флаш карти с разделени на срички думи със софтуер от АзБуки.ML и съдържащи звуков прочит, генериран от платформата.
                  </Card.Text>
                  <Card.Link href='/edu/audio-read'>Отвори</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='1'>
              Учебни игри и други интеграции
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Unity история</Card.Title>
                  <Card.Text>
                    Учебни игри със сюжети от българската история базирани на Unity. Включва аудио прочит и други езикови ресурси предоставени от АзБуки.edu.
                  </Card.Text>
                  <Card.Link href='http://www.apogee.online/games.html'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Войната на запетаите</Card.Title>
                  <Card.Text>
                    Учебна игра за 1-4 клас, в която учениците се състезават срещу изкуствения интелект на АзБуки.ML и се учат на правилна пунктуация.
                  </Card.Text>
                  <Card.Link href='#'>Очаквайте скоро</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='2'>
              Електронни и интерактивни учебници
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='2'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Интерактивни учебници</Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>С включен аудио прочит.</Card.Subtitle>
                  <Card.Text>
                    Електронни учебници на издателство "Просвета" с включен аудио прочит на текстовете и допълнителни интерактивни упражнения.
                  </Card.Text>
                  <Card.Link href='https://bg.e-prosveta.bg/demo/196?page=6'>Отвори</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='3'>
              Лингвистични инструменти и експерименти
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='3'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>PoS система и граматика</Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>Синтаксис на бълг. език</Card.Subtitle>
                  <Card.Text>
                    Инструменти за справка с речници и невронни мрежи, извършващи part of speech етикиране, граматичен и пунктуационен анализ.
                  </Card.Text>
                  <Card.Link href='/grammar'>Отвори инструмента</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Sentiment Analysis</Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>Machine Learning експерименти</Card.Subtitle>
                  <Card.Text>
                    Демонстрация на невронни мрежи, извършващи анализ на настроението в текстовете. Използвани са невронни мрежи с LSTM и GRU слоеве.
                  </Card.Text>
                  <Card.Link href='/sentiment'>Отвори инструмента</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Маркови вериги</Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>Лингвистични алгоритми</Card.Subtitle>
                  <Card.Text>
                    Демонстрация на маркови вериги допълващи изречения и по-специално български поговорки, както и фрази от класическата поезия.
                  </Card.Text>
                  <Card.Link href='/generation'>Отвори инструмента</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='4'>
              Школи и обучения
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='4'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Взаимодействие с ML в WEB проекти</Card.Title>
                  <Card.Text>
                    Лекции за имплементация на ML решения в модерните уеб приложения са част от ученическата школа на Радостин Чолаков в МГ Пловдив.
                  </Card.Text>
                  <Card.Link href='https://codeweek.eu/view/331542/skola-po-web-development-na-radostin-colakov'>Виж повече</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Zero to Hero: NLP with Tensorflow and Keras</Card.Title>
                  <Card.Text>
                    Първи стъпки в обработката на естествен език (NLP) с Tensorflow и Keras. Онлайн събитие организирано от Google Developers Group Sofia.
                  </Card.Text>
                  <Card.Link href='https://www.youtube.com/watch?v=ECRUJTKuKKs'>Гледай</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='5'>
              Научни публикации и доклади
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='5'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>АзБуки.ML: Изследователски доклад и технологична документация</Card.Title>
                  <Card.Text> </Card.Text>
                  <Card.Link href='https://docs.google.com/document/d/1_7v3Nh0iez98DTdGlREyyLfYC7DuIEqOar4zMhKLshQ/edit?usp=sharing'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Expo Sciences Luxembourg: ML Linguistic Platform with Neural Networks</Card.Title>
                  <Card.Text> </Card.Text>
                  <Card.Link href='http://illuxi-v3.s3.amazonaws.com/attachments/boot/9c068291750105dc575f6686e918fdad.pdf'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>ACL: Efficient TOD Systems with Response Selection as an Auxiliary Task</Card.Title>
                  <Card.Text> </Card.Text>
                  <Card.Link href='https://aclanthology.org/2022.icnlsp-1.2/'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='6'>
              Отворени данни
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='6'>
            <Card.Body>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Hurtlex data set</Card.Title>
                  <Card.Text>
                    Позитивни и негативни изрази на български език. Данните са базирани на лексикона hurtlex и са допълвани от АзБуки.ML и други източници.
                  </Card.Text>
                  <Card.Link href='https://github.com/AzBuki-ML/public-data/tree/master/polarity_lexicons'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>Пунктуационен data set</Card.Title>
                  <Card.Text>
                    Набор от данни съдържащи край на изреченията и запетаите в тях. Представляват анотирани извадки от книги и научни статии.
                  </Card.Text>
                  <Card.Link href='https://github.com/AzBuki-ML/public-data/tree/master/punctuation'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
              <Card className='card-item'>
                <Card.Body>
                  <Card.Title>PoS Tagset</Card.Title>
                  <Card.Text>
                    Разясненителни данни за отделните part of speech етикети в българския език и различните словоформи на включените думи.
                  </Card.Text>
                  <Card.Link href='https://github.com/AzBuki-ML/public-data/tree/master/pos_tagset'>Разгледай</Card.Link>
                </Card.Body>
              </Card>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </FadeIn>
    <br />
  </div>
)

export default EduPage
